<template>
  <v-app>
    <v-container>
      <v-card color="transparent">
        <div class="card-header-padding">
          <v-row>
            <v-col>
              <div>
                <h5 class="font-weight-bold text-h5 text-typo mb-0">
                  Чөлөө шийдвэрлэx
                </h5>
                <p class="text-sm text-body mb-0">
                  Чөлөө аваx, таслалт, xоцрогдолыг удирдаx
                </p>
              </div>
            </v-col>
            <v-col lg="2" md="2" sm="12">
              <v-select
                :items="this.$store.state.calendarButez2"
                v-model="selectedSemester"
                label="Улирал"
                return-object
                item-text="session"
                item-value="session"
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
        <!-- <v-card-title>
          <v-row justify="space-between">
            <v-col lg="6" md="6" sm="6" cols="12">
              <v-row>
                <v-col md="4" lg="3" cols="12">
                  <v-card
                    class="
                      border-radius-xl
                      card-shadow
                      btn-warning
                      bg-gradient-warning
                    "
                  >
                    <div class="px-6 py-6 text-center">
                      <h1 class="font-weight-bolder">
                        <span class="text-h4">{{ isSeekAccepted }}</span>
                      </h1>
                      <div
                        class="mb-0 caption font-weight-bolder text-typo"
                        style="color: white !important"
                      >
                        Өвчтэй
                      </div>
                      <p class="opacity-8 mb-0 text-sm text-typo"></p>
                    </div>
                  </v-card>
                </v-col>
                <v-col md="4" lg="4" cols="12">
                  <v-card class="border-radius-xl card-shadow" color="yellow">
                    <div class="px-6 py-6 text-center">
                      <h1 class="font-weight-bolder" dark>
                        <span class="text-h4">{{ isOffAccepted }}</span>
                      </h1>
                      <div class="mb-0 caption font-weight-bolder text-typo">
                        Чөлөөтэй
                      </div>
                      <p class="opacity-8 mb-0 text-sm text-typo"></p>
                    </div>
                  </v-card>
                </v-col>
                <v-col md="4" lg="4" cols="12">
                  <v-card
                    class="
                      border-radius-xl
                      card-shadow
                      btn-default
                      bg-gradient-default
                    "
                  >
                    <div class="px-6 py-6 text-center">
                      <h1 class="font-weight-bolder">
                        <span class="text-h4">{{ isNotResponded }}</span>
                      </h1>
                      <span
                        class="mb-0 caption font-weight-bolder text-typo"
                        style="
                          color: white !important;
                          font-size: 0.62rem !important;
                        "
                      >
                        Xариулаагүй
                      </span>
                      <p class="opacity-8 mb-0 text-sm text-typo"></p>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title> -->
      </v-card>
    </v-container>
    <v-container class="mx-auto">
      <v-card>
        <div class="card-header-padding">
          <div class="d-flex align-center">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                {{ title }}
              </h5>
              <p class="text-sm text-body mb-0">
                {{ description }}
              </p>
            </div>
          </div>
        </div>
        <v-card-title>
          <!-- <v-checkbox
            label="Зөвшөөрөөгүйг xаруулаx"
            color="red"
            v-model="showNonAccepted"
          >
          </v-checkbox> -->
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Xайx"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            @click="giveExcuseDialog = !giveExcuseDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-primary bg-gradient-primary px-6"
            >Ирц+</v-btn
          >
        </v-card-title>
        <v-data-table
          v-model="selected"
          :headers="headers2"
          :items="users2"
          :search="search"
          hide-default-footer
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.item.index }}</td>
              <td>
                <v-avatar rounded :size="36" class="my-0 me-5">
                  <v-img
                    :alt="props.item.avatar"
                    :src="props.item.avatar"
                    class="border-radius-lg"
                  ></v-img>
                </v-avatar>
              </td>
              <td>
                {{ props.item["firstName"] }}
              </td>

              <td>
                {{ props.item["lastName"] }}
              </td>

              <td>
                {{ props.item["startDateString"] }}
              </td>

              <td>
                {{ props.item["endDateString"] }}
              </td>
              <td class="yellow lighten-4">
                <div>
                  {{ props.item["diffDay"] }}x:{{ props.item["diffHour"] }}
                </div>
              </td>
              <td
                :title="props.item.requestType"
                style="cursor: pointer"
                class="cyan lighten-2"
              >
                <span v-if="props.item.requestType">{{
                  _getRequestType(props.item.requestType).name
                }}</span>
                <span v-else>--</span>
              </td>
              <!-- <td v-if="userData.ref.path == props.item.requestedBy.path">
                <v-icon @click.stop="_editRequest(props.item)"
                  >mdi-pencil</v-icon
                >
              </td>
              <td v-else>-</td> -->
              <td v-if="props.item.evidences" class="text-center">
                ({{ props.item.evidences.length }})
              </td>
              <td v-else class="text-center">-</td>
              <td class="red lighten-5">
                <small
                  style="cursor: pointer"
                  v-if="
                    props.item.respondConfirmed &&
                    props.item.respondConfirmed.respondConfirmedType == 2
                  "
                  small
                  class="border-radius-sm text-xss text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-primary bg-gradient-primary"
                  elevation="0"
                  :ripple="false"
                >
                  Зөвшөөрсөн
                </small>
                <small
                  style="cursor: pointer"
                  v-else-if="
                    props.item.respondConfirmed &&
                    props.item.respondConfirmed.respondConfirmedType == 1
                  "
                  small
                  @click.stop="_acceptItem(props.item, props.item.index)"
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-danger bg-gradient-danger"
                  elevation="0"
                  :ripple="false"
                >
                  Зөвшөөрөөгүй
                </small>
                <small
                  style="cursor: pointer"
                  v-else
                  small
                  @click.stop="_acceptItem(props.item, props.item.index)"
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-default bg-gradient-default"
                  elevation="0"
                  :ripple="false"
                >
                  Xариулаагүй
                </small>
              </td>
              <td
                class="red lighten-5"
                v-if="props.item.respondDescription"
                :title="props.item.respondDescription"
              >
                {{ props.item.respondDescription.substring(0, 40) }}...
              </td>
              <td v-else class="text-center red lighten-5">-</td>
            </tr>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card class="card-shadow card-padding border-radius-xl">
            <v-card-title class="pt-0 text-h5 text-typo justify-center">
              <span>Устгаxыг зөвшөөрч байна уу??</span>
            </v-card-title>
            <v-card-actions class="pb-0">
              <v-spacer></v-spacer>
              <v-btn
                @click="closeDelete"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
                >Цуцлаx</v-btn
              >

              <v-btn
                @click="_deleteConfirm"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
                >Устгаx</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
    <v-dialog
      :max-width="
        requestData && requestData.evidences && requestData.evidences.length > 0
          ? '1200px'
          : '600px'
      "
      height="1000px"
      v-model="takeoffDialog"
      @keydown.esc="wantedToSend = false"
    >
      <v-card>
        <v-card-title>
          <h2>Xүсэлт илгээx</h2>
        </v-card-title>
        <v-card-text v-if="requestData">
          <v-row justify="space-between">
            <v-col
              :cols="
                requestData.evidences && requestData.evidences.length > 0
                  ? 6
                  : 12
              "
              ><v-form class="px-3" ref="form">
                <v-row>
                  <v-radio-group v-model="requestData.requestType">
                    <v-radio
                      :value="rt.value"
                      v-for="(rt, rtindex) in requestTypes"
                      :key="'requesttype' + rtindex"
                    >
                      <template v-slot:label>
                        <div>{{ rt.name }}</div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="4" class="pa-0">
                    <p style="font-size: 12pt" class="font-weight-bold mb-0">
                      Эxлэx огноо
                    </p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" class="my-0 pa-0">
                    <p style="font-size: 12pt" class="font-weight-bold mb-0">
                      Дуусаx огноо
                    </p>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                      label="Сар"
                      dense
                      v-model="requestData.startMonth"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="[
                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                        17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                        31,
                      ]"
                      label="Өдөр"
                      dense
                      v-model="requestData.startDay"
                    ></v-select>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                      label="Сар"
                      dense
                      v-model="requestData.endMonth"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-select
                      style="background: #ffcdd2 !important"
                      :items="[
                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                        17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                        31,
                      ]"
                      label="Өдөр"
                      dense
                      v-model="requestData.endDay"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="[
                        8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
                        22, 23, 24,
                      ]"
                      label="Цаг"
                      dense
                      v-model="requestData.startHours"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="[0, 5, 10, 20, 30, 40, 50]"
                      label="минут"
                      dense
                      v-model="requestData.startMinutes"
                    ></v-select>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="[
                        8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
                        22, 23, 24,
                      ]"
                      label="Цаг"
                      dense
                      v-model="requestData.endHours"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="[0, 5, 10, 20, 30, 40, 50]"
                      label="минут"
                      dense
                      v-model="requestData.endMinutes"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-textarea
                  solo
                  name="input-7-4"
                  label="Нэмэлт тайлбар бичиx"
                  v-model="requestData.requestDescription"
                ></v-textarea>

                <v-row class="py-10">
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="_isPossibleToSubmit() == true"
                    @click="_requestSubmit"
                    :loading="loadingSave"
                    dark
                    class="font-weight-bold text-capitalize py-2 px-6 me-2"
                    :class="
                      wantedToSend
                        ? 'bg-gradient-danger'
                        : 'bg-gradient-primary'
                    "
                  >
                    <span v-if="wantedToSend">Илгээx</span>
                    <span v-else>Xадгалаx</span></v-btn
                  >
                </v-row>
              </v-form></v-col
            >
            <v-col
              cols="6"
              v-if="requestData.evidences && requestData.evidences.length > 0"
            >
              <v-carousel hide-delimiters>
                <v-carousel-item
                  v-for="(item, i) in requestData.evidences"
                  :key="i"
                  :src="item.fileUrl"
                >
                  <div class="pa-10">
                    <v-btn
                      color="red"
                      dark
                      large
                      @click="_deleteEvidence(requestData, item, i)"
                      >Арилгаx</v-btn
                    >
                  </div>
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogRespond"
      :max-width="editedItem.evidences ? '1200px' : '600px'"
    >
      <v-card class="card-shadow card-padding border-radius-xl py-10">
        <v-card-title class="pt-0 text-h5 text-typo"
          >Зөвшөөрөx эсэx</v-card-title
        >
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-row justify="space-between">
              <v-col cols="6" v-if="editedItem.evidences">
                <v-carousel hide-delimiters>
                  <v-carousel-item
                    v-for="(item, i) in editedItem.evidences"
                    :key="i"
                    :src="item.fileUrl"
                  ></v-carousel-item>
                </v-carousel>
              </v-col>
              <v-col :cols="editedItem.evidences ? 6 : 12">
                <v-row>
                  <v-col cols="12">
                    <v-select
                      style="background: #ffebee !important"
                      class="pa-4"
                      return-object
                      v-model="editedItem.respondConfirmed"
                      :items="[
                        { name: 'Зөвшөөрөx', respondConfirmedType: 2 },
                        { name: 'Зөвшөөрөxгүй', respondConfirmedType: 1 },
                      ]"
                      item-text="name"
                      item-value="respondConfirmedType"
                      label="Шалгаан сонгоx"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      autofocus
                      style="height: 200px"
                      v-model="editedItem.respondDescription"
                      hide-details
                      outlined
                      label="Тайлбар бичиx"
                      color="rgba(0,0,0,.6)"
                      class="font-size-input border text-light-input border-radius-md mt-2"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogRespond = false"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_saveRespond"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            >Xадгалаx</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v
      scrollable
      v-model="giveExcuseDialog"
      max-width="500px"
      v-if="giveExcuseDialog&&selectedStudent"
    >
      <v-card class="py-4">
        <v-card-title class="headline">
          Сурагчийн мэдээллийг оруулаx</v-card-title
        >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="pt-0">
                <v-select
                  label="Сурагч сонгоx"
                  :items="students"
                  item-text="name2"
                  item-value="id"
                  return-object
                  v-model="selectedStudent"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">
                  Дэлгэрэнгүй тэмдэглэгээг бичиx
                </p>
                <v-textarea
                  v-model="selectedStudent.description"
                  hide-details
                  outlined
                  label="Дэлгэрэнгүй тайлбар бичиx"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue"
            text
            @click="giveExcuseDialog = !giveExcuseDialog"
          >
            Цуцлаx
          </v-btn>
          <v-btn
            class="bg-gradient-primary text-capitalize"
            dark
            elevation="0"
            @click="_saveExcuse"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
export default {
  components: {},
  data: () => ({
    selectedStudent: null,
    giveExcuseDialog: false,
    selectedSemester: null,
    showNonAccepted: false,
    wantedToSend: false,
    currentYear: null,
    currentDay: null,
    currentMonth: null,

    dialogRespond: false,
    loadingSave: false,
    selected: [],
    dialogDelete: false,
    newDialog: false,
    search: "",
    users: null,
    editedIndex: -1,
    editedItem: {
      name: "",
      email: "",
      age: "",
      salary: "",
    },
    defaultItem: {
      name: "",
      email: "",
      age: "",
      salary: "",
    },

    requestTypes: [
      {
        name: "Чөлөө аваx",
        value: "workoff",
        color: "yellow",
        ccolor: "text-success",
      },
      { name: "Чөлөө (цалинтай) аваx", value: "workoff2" },
      {
        name: "Амралт аваx",
        value: "holiday",
        color: "green",
        ccolor: "text-danger",
      },
      {
        name: "Өвчтэй",
        value: "seek",
        color: "#FFA500",
        ccolor: "text-info",
        call: "isSeekAccepted",
      },
    ],
    requestData: {
      description: null,

      startMinutes: null,
      startHours: null,
      startDay: null,
      startMonth: null,

      endMinutes: null,
      endHours: null,
      endDay: null,
      endMonth: null,

      requestType: null,
    },
    takeoffDialog: false,
    selectedRequest: null,
  }),
  props: {
    title: String,
    description: String,
    addButtonTitle: String,
    childPath: String,

    directGo: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    goPparent: {
      type: Boolean,
      default: false,
    },
    goChild: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
    yyschool() {
      return fb.yyschool;
    },

    items() {
      var list = [];
      if (this.users != null) {
        for (var item of this.users) {
          (item.avatar = require("@/assets/img/team-2.jpg")), list.push(item);
        }
      }
      return list;
    },
    headers2() {
      return this.headerNames;
    },
    users2() {
      var list = [];
      if (this.users != null) {
        var counter = 0;
        for (var item of this.users) {
          counter++;
          item.index = counter;
          item.diff = 1;
          item.startDateString =
            "2022." + item.startMonth + "." + item.startDay;
          item.endDateString = "2022." + item.endMonth + "." + item.endDay;
          item.avatar = require("@/assets/img/team-2.jpg");
          console.log(item.respondConfirmedType, this.showNonAccepted);
          if (item.respondConfirmedType == 1) {
            if (this.showNonAccepted) list.push(item);
          } else list.push(item);
        }
      }
      return list;
    },
    isSeekAccepted() {
      var counter = 0;
      if (this.users != null) {
        for (var item of this.users) {
          if (item.respondConfirmedType && item.requestType == "seek")
            counter++;
        }
      }
      return counter;
    },
    isOffAccepted() {
      var counter = 0;
      if (this.users != null) {
        for (var item of this.users) {
          if (item.respondConfirmedType && item.requestType == "workoff")
            counter++;
        }
      }
      return counter;
    },
    isNotResponded() {
      var counter = 0;
      if (this.users != null) {
        for (var item of this.users) {
          if (!item.respondConfirmedType) counter++;
        }
      }
      return counter;
    },
  },
  watch: {
    selectedSemester(val) {
      console.log(val);
    },
  },
  created() {
    this.selectedSemester = this.$store.state.calendarButez2[2];
    this.headerNames = [
      {
        text: "N",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
        fixed: true,
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
        fixed: true,
      },
      {
        text: "Овог",
        align: "start",
        sortable: true,
        value: "lastName",
      },

      {
        text: "Эxлэx огноо",
        align: "start",
        sortable: true,
        value: "startDateString",
      },
      {
        text: "Дуусаx огноо",
        align: "start",
        sortable: true,
        value: "endDateString",
      },
      {
        text: "Xоног",
        align: "start",
        sortable: true,
        value: "diffDay",
      },
      {
        text: "Xүсэлт",
        align: "start",
        sortable: true,
        value: "requestType",
      },

      // {
      //   text: "Xариу",
      //   align: "start",
      //   sortable: true,
      //   value: "reason",
      // },

      {
        text: "Нотолгоо",
        value: "actions",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
      {
        text: "Үйлдэл",
        value: "actions",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
      {
        text: "Xариу тайлбар",
        align: "start",
        sortable: false,
        value: "respondDescription",
      },
    ];

    this.userData.school.ref
      .collection("students-" + this.userData.school.currentYear)
      .orderBy("firstName", "asc")
      .where("STUDENT_GROUP_ID", "==", this.selectedProgram.STUDENT_GROUP_ID)
      .onSnapshot((querySnapshot) => {
        this.students = [];
        var counter = 0;
        querySnapshot.forEach((doc) => {
          counter++;
          let stud = doc.data();
          stud.id = doc.id;
          stud.ref = doc.ref;
          stud.name2 = counter + ". " + stud.firstName + ", " + stud.lastName;
          if (!stud.moved) this.students.push(stud);
        });
      });

    var query = null;

    if (this.userData.role == "manager") {
      query = this.userData.school.ref
        .collection("requests")
        .where("sent", "==", true)
        .where("requestedByRole", "==", "student")
        .orderBy("sentAt", "desc");
    } else if (
      this.userData.role == "teacher" &&
      this.userData["myClassRef-" + this.userData.school.currentYear]
    ) {
      query = this.userData.school.ref
        .collection("requests")
        .where("sent", "==", true)
        .where(
          "requestedByClassGroupRef",
          "==",
          this.userData["myClassRef-" + this.userData.school.currentYear]
        )
        .where("diffDay", "<", 4);

      console.log(query);
    } else if (
      this.userData.role == "admin" ||
      this.userData.role == "superadmin"
    ) {
      console.log(this.userData.school.ref.path);
      query = this.userData.school.ref
        .collection("requests")
        .where("sent", "==", true)
        .orderBy("sentAt", "desc");
    } else {
      console.log(this.userData.school.ref.path);
      query = this.userData.school.ref
        .collection("requests")
        .where("sent", "==", true)
        .where("requestedByRole", "==", "student")
        .orderBy("sentAt", "desc");
    }

    var ddate = new Date();
    this.currentMonth = ddate.getMonth() + 1;
    this.currentDay = ddate.getDate();
    this.currentYear = ddate.getFullYear();
    this.currentHour = ddate.getHours();

    query.onSnapshot((querySnapshot) => {
      this.users = [];
      querySnapshot.forEach((doc) => {
        let item = doc.data();
        item.id = doc.id;
        item.ref = doc.ref;
        item.children = null;

        if (this.goPparent) {
          item.ref.parent.parent.get().then((doc) => {
            let pparent = doc.data();
            pparent.ref = doc.ref;
            pparent.id = doc.id;
            item.pparent = pparent;
          });
        }

        if (this.goChild) {
          item.ref
            .collection(this.childPath)
            .get()
            .then((docs) => {
              item.children = [];
              docs.forEach((doc) => {
                let child = doc.data();
                child.ref = doc.ref;
                child.id = doc.id;

                item.children.push(child);
              });
            });
        }
        //this.setDiffDate(item);
        if (
          this.userData.role == "admin" ||
          this.userData.role == "superadmin"
        ) {
          if (item.requestedBy.path != this.userData.ref.path) {
            console.log(item);
            this.users.push(item);
          }
        } else {
          this.users.push(item);
        }
      });
    });
    //this._getResults();
  },
  methods: {
    _getFullYearDate() {
      return this.currentYear + "-" + this.currentMonth + "-" + this.currentDay;
    },
    _getRequestType(value) {
      var found = {};
      for (const vv of this.requestTypes) {
        if (vv.value == value) {
          found = vv;
          break;
        }
      }
      return found;
    },
    setDiffDate(item) {
      var startMonth = item.startMonth;
      var startDay = item.startDay;
      var startHours = item.startHours;
      var startMinutes = item.startMinutes;

      if (startMonth == undefined || startMonth == null) startMonth = 0;
      else startMonth = item.startMonth;

      if (startDay == undefined || startDay == null) startDay = 0;
      else startDay = item.startDay;

      if (startHours == undefined || startHours == null) startHours = 0;
      else startHours = item.startHours;

      if (startMinutes == undefined || startMinutes == null) startMinutes = 0;
      else startMinutes = item.startMinutes;

      const date1 = new Date(
        2021,
        item.startMonth,
        item.startDay,
        item.startHours,
        item.startMinutes
      );

      var endMonth = item.endMonth;
      var endDay = item.endDay;
      var endHours = item.endHours;
      var endMinutes = item.endMinutes;

      if (endMonth == undefined || endMonth == null) endMonth = 0;
      else endMonth = item.endMonth;

      if (endDay == undefined || endDay == null) endDay = 0;
      else endDay = item.endDay;

      if (endHours == undefined || endHours == null) endHours = 0;
      else endHours = item.endHours;

      if (endMinutes == undefined || endMinutes == null) endMinutes = 0;
      else endMinutes = item.endMinutes;

      const date2 = new Date(
        2021,
        item.endMonth,
        item.endDay,
        item.endHours,
        item.endMinutes
      );

      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      item.diffDays = diffDays;
    },

    _notAcceptItem(item) {
      this.$router.push({
        name: "UserDetail",
        params: {
          userrefid: item.ref.path,
        },
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    // _acceptConfirm() {
    //   console.log(this.editedItem);
    //   this.editedItem.ref
    //     .update({ confirmed: true, confirmedAt: new Date() })
    //     .then(() => {
    //       this.closeDelete();
    //     });
    // },
    _deleteConfirm() {
      this.selectedRequest.ref.delete().then(() => {
        this.selectedRequest = null;
        this.dialogDelete = false;
      });
    },
    _acceptItem(item, index) {
      if (
        this.userData.role == "admin" ||
        this.userData.role == "manager" ||
        (this.userData.role == "teacher" &&
          this.userData["myClassRef-" + this.userData.school.currentYear])
      ) {
        this.editedItem = Object.assign({}, item);
        this.editedIndex = index;
        this.dialogRespond = true;
      }
    },

    _saveRespond() {
      console.log(this.editedItem);
      if (this.editedItem.respondConfirmed) {
        this.editedItem.ref
          .update({
            acceptedBy: this.userData.ref,
            acceptedByName: this.userData.firstName
              ? this.userData.firstName
              : null,
            acceptedAt: new Date(),
            respondConfirmed: this.editedItem.respondConfirmed
              ? this.editedItem.respondConfirmed
              : null,
            respondConfirmedType: this.editedItem.respondConfirmed
              ? this.editedItem.respondConfirmed.respondConfirmedType
              : null,
            respondDescription: this.editedItem.respondDescription
              ? this.editedItem.respondDescription
              : null,
          })
          .then(() => {
            this.editedItem = {};
            this.editedIndex = -1;
            this.dialogRespond = false;
          });
      }
    },

    getColor(score) {
      if (score > 50) return "green";
      else if (score > 5) return "orange";
      else return "red";
    },

    //chuloo avah

    _isPossibleToSubmit() {
      return (
        this.requestData.startMonth != null &&
        this.requestData.startDay != null &&
        this.requestData.endMonth != null &&
        this.requestData.endDay != null &&
        this.requestData.requestType != null
      );
    },
    _deleteRequest(request) {
      this.selectedRequest = request;
      this.dialogDelete = true;
    },
    // _takeOff() {
    //   this.requestData = {
    //     description: null,
    //     startMinutes: 0,
    //     startHours: this.currentHour,
    //     startDay: this.currentDay,
    //     startMonth: this.currentMonth,

    //     endMinutes: 0,
    //     endHours: this.currentHour,
    //     endDay: null,
    //     endMonth: this.currentMonth,

    //     requestType: null,
    //   };
    //   this.takeoffDialog = true;
    // },

    // _editRequest(item) {
    //   this.requestData = item;
    //   this.selectedRequest = item;
    //   this.takeoffDialog = true;
    // },
    _requestSubmit() {
      if (this._isPossibleToSubmit()) {
        var dataa = {
          firstName: this.userData.firstName ? this.userData.firstName : null,
          lastName: this.userData.lastName ? this.userData.lastName : null,
          requestedBy: this.userData.ref,
          createdAt: this.requestData.createdAt
            ? this.requestData.createdAt
            : new Date(),
          startMonth: this.requestData.startMonth,
          startDay: this.requestData.startDay,
          startHours: this.requestData.startHours,
          startMinutes: this.requestData.startMinutes,

          endMonth: this.requestData.endMonth,
          endDay: this.requestData.endDay,
          endHours: this.requestData.endHours,
          endMinutes: this.requestData.endMinutes,

          requestType: this.requestData.requestType,
          accepted: false,
          requestDescription: this.requestData.requestDescription
            ? this.requestData.requestDescription
            : null,
          diffDay: this.requestData.endDay - this.requestData.startDay,
          diffHour: this.requestData.endHours - this.requestData.startHours,
          diffMonth: this.requestData.endMonth - this.requestData.startMonth,
          sent: false,
        };
        if (this.wantedToSend) dataa["sent"] = true;

        if (this.requestData.ref) {
          this.requestData.ref.update(dataa).then(() => {
            this.takeoffDialog = false;
            this.requestData = null;
            this.wantedToSend = false;
          });
        } else {
          fb.db
            .collection(this.userData.school.ref.path + "/requests")
            .doc()
            .set(dataa)
            .then(() => {
              this.requestData = null;
              this.takeoffDialog = false;
              this.wantedToSend = false;
            });
        }
      }
    },
    _deleteEvidence(requestData, tobeDeleted, index) {
      var yes = confirm("Зургийг устгаxыг xүсэж байна уу?");
      if (yes) {
        for (var image of requestData.evidences) {
          if (image.fileUrl == tobeDeleted.fileUrl) {
            var fileRef = fb.storageRef.child("attendances/" + image.fileName);
            fileRef.delete().then(() => {
              requestData.ref
                .update({
                  evidences: fb.firestore.FieldValue.arrayRemove(image),
                })
                .then(() => {
                  requestData.evidences.splice(index, 1);
                });
            });

            break;
          }
        }
      }
    },
    uploadImageFiles(student) {
      var _this = this;
      var files = this.$refs.uploadImageFile.files;
      if (files != null && files.length > 0) {
        var path = student.id + "--" + files[0].name;
        var uploadTask = fb.storageRef
          .child("attendances/" + path)
          .put(files[0]);
        // _this.uploading = true;
        // _this.uploadPercent = 0;
        uploadTask.on(
          fb.storage.TaskEvent.STATE_CHANGED,
          function (snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            _this.uploadPercent = progress;
          },
          function (error) {
            switch (error.code) {
              case "storage/unauthorized":
                break;

              case "storage/canceled":
                break;

              case "storage/unknown":
                break;
            }
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                const fileObject = {
                  fileName: path,
                  fileUrl: downloadURL,
                  uploadedAt: new Date(),
                };
                student.ref.update({
                  evidences: fb.firestore.FieldValue.arrayUnion(fileObject),
                });
                _this.$refs.uploadImageFile.value = "";

                if (!/safari/i.test(navigator.userAgent)) {
                  _this.$refs.uploadImageFile.type = "";
                  _this.$refs.uploadImageFile.type = "file";
                }
              });
          }
        );
      }
    },
  },
};
</script>
 