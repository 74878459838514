<template>
  <v-container class="px-0 mx-0" fluid>
    <v-card icon="mdi-clipboard-text" icon-small color="accent">
      <v-row class="mx-4">
        <v-alert
          color="#F8BBD0"
          class="pt-4 mb-10"
          text
          prominent
          icon="mdi-check-circle-outline"
          width="60%"
        >
          <div style="color: #d81b60; font-weight: normal">
            Нэг бүлэг дээр тавьсан шалгалт нөгөө бүлэг дээр АВТОМАТААР гарч ирэx
            тул, та шалгалтын огноог <v-icon>mdi-pencil</v-icon> засаx товчоор
            засаж туxайн бүлгийнxээ үнэлгээг оруулж болно!
          </div>
        </v-alert>

        <!-- <v-col md="2" lg="2" cols="2" class="pa-0 ml-2" v-if="$attrs.lesson&&$attrs.lesson.esisLessonType.esisLessonTypeId > 1">
            <v-select :items="$attrs.lesson.lessonGroups" v-model="selectedGroup" label="Group" return-object
              item-text="name" item-value="id">
            </v-select>
          </v-col> -->
        <v-col class="text-end">
          <v-btn
            v-if="editAllowed"
            elevation="0"
            class="text-capitalize mb-2 bg-gradient-primary"
            dark
            @click="_newDialog(null)"
          >
            Үнэлгээ+
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        fixed-header
        style=""
        :headers="headers"
        :items="filteredExams"
        :items-per-page="500"
        hide-details
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td
              class="px-0 text-center"
              style="width: 5%"
              @click="_print(props.item)"
            >
              {{ props.item.index }}
            </td>
            <!-- <td>{{ props.item.id }}</td> -->
            <td @click="_recoverStudent(props.item)" style="width: 10%">
              {{ _getExamDate(props.item) }}
            </td>
            <td class="text-uppercase red--text" style="width: 2%">
              {{ props.item.id.substring(0, 5) }}
            </td>
            <td
              v-if="props.item.examName"
              :title="props.item.examName"
              style="width: 20%"
            >
              {{ _getChange(props.item, "examName") }}
            </td>
            <td style="width: 5%">
              {{ _getStudentNumber(props.item) }}
            </td>
            <td style="width: 20%">
              {{ props.item.examDescription }}
            </td>
            <td style="width: 20%">
              {{ props.item.examConclusion }}
              <!-- mdi-check-circle -->
            </td>
            <td class="" style="width: 20%" v-if="editAllowed">
              <template>
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="_deleteItem(props.item)"
                >
                  mdi-delete
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="_editItem(props.item, props.item.index)"
                >
                  mdi-pencil
                </v-icon>
                <v-btn
                  dark
                  elevation="0"
                  small
                  class="ml-2 bg-gradient-danger"
                  @click.stop="_doUnelgee(props.item)"
                >
                  үнэлэх
                </v-btn>
                <!-- <v-btn
                  small
                  v-if="!props.item.completed"
                  dark
                  elevation="0"
                  class="ml-2 bg-gradient-info"
                  @click.stop="_confirmUnelgee(props.item, true)"
                >
                  Хаах
                </v-btn>
                <v-btn
                  style="min-width: 16px"
                  color="transparent"
                  title="Баталгаажсан"
                  v-else
                  elevation="0"
                  class="px-0 text-capitalize ml-2"
                  @click="_confirmUnelgee(props.item, false)"
                >
                  <v-icon color="green" size="22">mdi-check-circle</v-icon>
                </v-btn> -->
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="newDialog" max-width="60%" scrollable>
      <v-card class="py-4">
        <v-card-title class="headline text-typo font-weight-bold">
          Үнэлгээний үзүүлэлт -
          <span class="blue--text">
            {{ $attrs.lesson.classGroups[0].classGroupFullName }}</span
          >
        </v-card-title>
        <p v-if="$attrs.lesson" class="blue--text mx-6">
          {{ $attrs.lesson.courseInfo.COURSE_NAME }}
        </p>
        <v-divider></v-divider>
        <v-card-text class="px-10 pt-10">
          <v-row>
            <v-col cols="12" md="12" lg="12">
              <v-row>
                <v-col
                  cols="8"
                  md="8"
                  lg="8"
                  v-if="
                    editedItem.examDate ||
                    editedItem[
                      'examDate-' +
                        $attrs.lesson.classGroups[0].STUDENT_GROUP_ID
                    ] ||
                    editedItem['examDate-' + $attrs.lesson.id]
                  "
                >
                  Шалгалтын огноо
                  <h3
                    class="red--text"
                    v-if="editedItem['examDate-' + $attrs.lesson.id]"
                  >
                    {{ editedItem["examDate-" + $attrs.lesson.id] }}
                  </h3>

                  <h3
                    class="red--text"
                    v-else-if="
                      editedItem[
                        'examDate-' +
                          $attrs.lesson.classGroups[0].STUDENT_GROUP_ID
                      ]
                    "
                  >
                    {{
                      editedItem[
                        "examDate-" +
                          $attrs.lesson.classGroups[0].STUDENT_GROUP_ID
                      ]
                    }}
                  </h3>
                  <h3 class="red--text" v-else-if="editedItem.examDate">
                    {{ editedItem.examDate }}
                  </h3>
                </v-col>
                <v-col cols="8" md="8" lg="8" v-else>
                  <h3 class="red--text text-uppercase">
                    Шалгалтын огноогоо сонгоорой!
                  </h3>
                </v-col>

                <v-col cols="4" class="text-end py-0">
                  <v-btn
                    class="text-capitalize bg-gradient-danger"
                    dark
                    elevation="0"
                    small
                    @click="_showSelectDateDialog"
                  >
                    <span
                      v-if="
                        editedItem.examDate ||
                        editedItem[
                          'examDate-' +
                            $attrs.lesson.classGroups[0].STUDENT_GROUP_ID
                        ] ||
                        editedItem['examDate-' + $attrs.lesson.id]
                      "
                      >Засаx</span
                    >
                    <span v-else>Огноо+</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12" lg="12" class="py-0">
              <v-text-field
                v-model.trim="editedItem.examName"
                label="Үнэлгээний сэдвийн нэр"
              />
            </v-col>
            <!-- <v-col cols="12" md="12" lg="12" v-if="selectTopic1">
                <v-select
                  :items="selectTopic1.children"
                  v-model="selectTopic2"
                  clearable
                  multiple
                  chips
                  label="Сэдэв сонгоx"
                  return-object
                  item-text="courseTopicName"
                  item-value="id"
                >
                </v-select>
              </v-col> -->
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" sm="12" md="12">
              <p class="text-wrap text-typo font-weight-bold">Тэмдэглэл</p>
              <v-textarea
                v-model="editedItem.examDescription"
                hide-details
                outlined
                label="Тайлбар"
                color="rgba(0,0,0,.6)"
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <p class="text-typo font-weight-bold">Дүгнэлт</p>
              <v-textarea
                v-model="editedItem.examConclusion"
                hide-details
                outlined
                label="Тайлбар"
                color="rgba(0,0,0,.6)"
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mx-4">
          <v-spacer />
          <v-btn
            color="blue"
            style="background: #ececec"
            text
            @click="_closeEdit"
            class="mr-2"
          >
            Цуцлаx
          </v-btn>
          <template>
            <v-btn
              v-if="
                (editedItem.examDate ||
                  editedItem[
                    'examDate-' + $attrs.lesson.classGroups[0].STUDENT_GROUP_ID
                  ] ||
                  editedItem['examDate-' + $attrs.lesson.id]) &&
                (editedItem.examTopic || editedItem.examName)
              "
              class="bg-gradient-primary text-capitalize"
              dark
              elevation="0"
              @click="_saveExam"
            >
              Xадгалаx
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="showDateSelectDialog" max-width="400px">
      <v-card>
        <v-card-text class="text-center pb-0">
          <v-date-picker
            v-model="selectedDate"
            color="green lighten-1"
            header-color="green"
          ></v-date-picker>
        </v-card-text>

        <v-card-text class="text-center pb-6">
          <v-btn
            class="mr-2"
            @click="showDateSelectDialog = !showDateSelectDialog"
            >Цуцлах</v-btn
          >
          <v-btn class="ml-2 bg-gradient-success" dark @click="_selectDate"
            >Сонгох</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="detailDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline">
          {{ editedItem.name }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p>{{ editedItem.name }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <p>{{ editedItem.shortName }}</p>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <p>{{ editedItem.code }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Тайлбар</p>
                {{ editedItem.description }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" :max-width="400" :style="{ zIndex: 1 }">
      <v-card class="px-2 py-6">
        <v-card-title class="text-typo font-weight-bold">
          Устгаxыг xүсэж байна уу?
        </v-card-title>
        <v-card-text class="red--text"
          >Хэрэв энэ үнэлгээнд дүнгүүд байгаа бол БҮХ бүлгийн дүнгүүд
          устгагдана!</v-card-text
        >
        <v-card-text class="black--text" v-html="editedItem.name"></v-card-text>
        <v-card-actions class="px-2">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="deleteDialog = !deleteDialog"
            >Цуцлаx</v-btn
          >
          <v-btn
            color="bg-gradient-danger text-capitalize"
            dark
            @click="_deleteOK()"
            >Устгах</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="unelgeeDialog"
      width="80%"
      v-if="currentSelectedExam"
      persistent
      scrollable
    >
      <v-card class="py-4 scoreTables">
        <div class="card-header-padding pb-0">
          <v-row>
            <v-col>
              <h5 class="font-weight-bold text-h5 text-typo mb-0 ml-4">
                Үнэлгээ-1 /<span class="red--text">{{
                  _getExamDate(currentSelectedExam)
                }}</span
                >/<span
                  class="blue--text"
                  v-if="currentSelectedExam.examTopic"
                  >{{
                    currentSelectedExam.examTopic.parentCourseTopicName
                  }}</span
                >
                <span v-else>, {{ currentSelectedExam.examName }}</span>
              </h5>
              <p class="ml-4" v-if="$attrs.lesson">
                {{ $attrs.lesson.courseInfo.COURSE_NAME }}/
                <span
                  class="blue--text"
                  v-if="
                    $attrs.lesson.classGroups &&
                    $attrs.lesson.classGroups.length > 0
                  "
                >
                  {{ $attrs.lesson.classGroups[0].classGroupFullName }} </span
                ><span v-if="$attrs.students" light
                  >, {{ $attrs.students.length }} сурагч</span
                >
              </p>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>
        <ScoreInputDialog
          class="pt-0"
          v-if="$attrs.students && $attrs.students.length > 0"
          v-bind="$attrs"
          :students="$attrs.students"
          :currentSelectedExam="currentSelectedExam"
          :lesson="$attrs.lesson"
        >
        </ScoreInputDialog>
        <v-card-text
          class="mx-10 py-10 mt-4 px-4 text-center"
          style="width: 80%; overflow: hidden"
          v-if="savingUnelgee"
        >
          <p style="display: block !important" class="red--text">
            Дүн хадгалж байна. Түр хүлээнэ үү!
          </p>
          <v-progress-linear
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions class="mx-6 py-4 mt-4" v-else>
          <v-spacer></v-spacer>
          <v-btn color="grey" class="white--text" @click="_closeUnelgeeDialog">
            Цуцлах
          </v-btn>
          <v-btn
            @click="_saveUnelgee1OfStudents"
            class="bg-gradient-success white--text ml-2 mr-3 text-capitalize"
          >
            Хадгалах
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="studentsTobeDelete"
      v-model="showUnelgeeDeleteDialog"
      scrollable
      width="60%"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <h3>
                Үнэлгээтэй сурагчид
                <span v-if="studentsTobeDelete"
                  >({{
                    studentsTobeDelete.filter((ss) => ss.selectedGroup).length
                  }})</span
                >
              </h3>
            </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="showUnelgeeDeleteDialog = !showUnelgeeDeleteDialog"
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          v-if="studentsTobeDelete.filter((ss) => ss.selectedGroup).length > 0"
        >
          <p style="background-color: yellow" class="py-2 px-2">
            Дарааx сурагчид үнэлгээ дүнтэй байна. Шалгалтыг устгавал дүнгүүд
            xамт устгагдана!
          </p>
          <p
            v-for="(student, sIndex) in studentsTobeDelete.filter(
              (ss) => ss.selectedGroup
            )"
            :key="sIndex"
          >
            <v-row class="mx-2">
              <v-col>
                {{ student.index }} .
                <span class="font-weight-bold"
                  >{{ student["FIRST_NAME"] }},</span
                >
                {{ student["LAST_NAME"] }}
                <span
                  v-if="
                    $attrs.lesson.esisLessonType &&
                    $attrs.lesson.esisLessonType.esisLessonTypeId == 3
                  "
                >
                  ,
                  <span class="blue--text">
                    {{ student["STUDENT_GROUP_NAME"] }}</span
                  >
                </span>
                <!-- <p>{{ student.ref.path }}</p> -->
              </v-col>
              <v-col class="text-end blue--text" lg="2" md="2" sm="2" cols="2">
                {{ student.STUDENT_GROUP_NAME }}
              </v-col>
              <v-col class="text-end" lg="2" md="2" sm="2" cols="2">
                {{ student.score }}
              </v-col>
              <v-col class="text-end" lg="2" md="2" sm="2" cols="2">
                {{ student.scoreLevelString }}
              </v-col>
            </v-row>
          </p>
        </v-card-text>
        <v-card-text
          v-if="
            _getRemainGroups(studentsTobeDelete) &&
            studentsTobeDelete.filter((ss) => ss.selectedGroup).length == 0
          "
        >
          <strong class="red--text">[АНXААРУУЛГА]</strong>

          <p class="text-h6">
            <span class="blue--text">
              {{ _getRemainGroups(studentsTobeDelete).join(",") }}</span
            >
            дээр дүн байгаа тул энэ шалгалтыг устгаx боломжгүй байна! Та
            <span class="blue--text">
              {{ _getRemainGroups(studentsTobeDelete).join(",") }}</span
            >
            бүлгээ сонгоод тус бүрд нь дүнгээ устгана уу.
          </p>
          <p class="yellow red--text pa-2 text-h6">
            ЭСВЭЛ ТА шалгалтын ОН САРАА өөрчлөөд энэ шалгалтанд энэ ангийн
            үнэлгээг оруулаx боломжтой.
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-10 my-3">
          <v-btn
            color="grey"
            class="white--text"
            @click="showUnelgeeDeleteDialog = !showUnelgeeDeleteDialog"
          >
            Цуцлах
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="
              studentsTobeDelete.filter((ss) => ss.selectedGroup).length > 0
            "
            @click="_deleteAllScoreAndExam"
            class="bg-gradient-danger white--text ml-2 mr-3 text-capitalize"
          >
            Үнэлгээ устгаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="studentsTobeRecovery"
      v-model="showRecoveryDialog"
      scrollable
      width="40%"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <h3>
                Үнэлгээтэй сурагчид
                <span v-if="studentsTobeRecovery"
                  >({{ studentsTobeRecovery.length }})</span
                >
              </h3>
            </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="showRecoveryDialog = !showRecoveryDialog"
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <p v-for="(student, sIndex) in studentsTobeRecovery" :key="sIndex">
            <v-row class="mx-2">
              <v-col>
                {{ student.index }} .
                <span class="font-weight-bold"
                  >{{ student["FIRST_NAME"] }},</span
                >
                {{ student["LAST_NAME"] }}
                <span v-if="$attrs.lesson.mixed">
                  ,
                  <span class="blue--text">
                    {{ student["STUDENT_GROUP_NAME"] }}</span
                  >
                </span>
              </v-col>
              <v-col class="text-end" lg="2" md="2" sm="2" cols="2">
                {{ student.score }}
              </v-col>
            </v-row>
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-10 my-3">
          <v-btn
            color="grey"
            class="white--text"
            @click="showRecoveryDialog = !showRecoveryDialog"
          >
            Цуцлах
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="_recoverScores"
            class="bg-gradient-danger white--text ml-2 mr-3 text-capitalize"
          >
            Сэргээх
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import ScoreInputDialog from "./ScoreInputDialog";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
import { sync } from "vuex-pathify";
import Vue from "vue";
Vue.use(Donut);
export default {
  name: "Unelgee1Exams",
  data: () => ({
    editAllowed: false,
    showRecoveryDialog: false,
    studentsTobeRecovery: null,

    showUnelgeeDeleteDialog: false,
    studentsTobeDelete: null,
    selectedGroup: null,
    savingUnelgee: false,
    temporarlySelectedLesson: null,
    selectTopic1: null,
    selectTopic2: null,
    chartProps: {
      size: 25,
      thickness: 50,
    },
    sections: [{ value: 35 }],
    unelgeeDialog: false,
    selectedDate: null,
    showDateSelectDialog: false,
    currentSelectedExam: null,
    search: "",
    detailDialog: false,
    newDialog: false,
    newBulkDialog: false,
    deleteDialog: false,
    editedItem: {},
    editedIndex: -1,
    defaultItem: {
      name: "",
      description: "",
    },
    items: [],
    messageNotification: null,
    bulkText: null,
  }),
  components: {
    ScoreInputDialog,
  },
  props: {
    teacher: {
      type: Object,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    selectedTeacher() {
      return this.teacher;
    },
    filteredExams() {
      var list = [];
      if (this.$attrs.examInfos) {
        var counter = 0;
        for (const cc of this.$attrs.examInfos) {
          counter++;
          cc.index = counter;
          list.push(cc);
        }
      }
      return list;
    },
    headers() {
      return [
        {
          text: "No.",
          align: "center",
          value: "index",
          width: "5%",
        },
        {
          text: "Огноо",
          align: "start",
          sortable: true,
          value: "selectedDate",
          width: "10%",
        },
        {
          text: "Код",
          align: "start",
          sortable: true,
          value: "topic.parentCourseTopicName",
          width: "5%",
        },
        {
          text: "Нэгж сэдвийн нэр",
          align: "start",
          sortable: true,
          value: "topic.parentCourseTopicName",
          width: "20%",
        },
        {
          text: "Too",
          align: "start",
          sortable: true,
          value: "numberOfScoredStudents",
          width: "5%",
        },
        {
          text: "Тайлбар",
          align: "start",
          sortable: true,
          value: "description",
          width: "20%",
        },
        {
          text: "Дүгнэлт",
          align: "start",
          sortable: true,
          value: "conclusion",
          width: "20%",
        },
        {
          text: "Үйлдэл",
          align: "start",
          sortable: true,
          value: "Actions",
          width: "20%",
        },
        // {
        //   text: "Сэдвийн тоо",
        //   align: "start",
        //   sortable: true,
        //   value: "selectedDate",
        // },

        // {
        //   text: "ҮСТ",
        //   align: "start",
        //   sortable: true,
        //   value: "selectedDate",
        // },
        // {
        //   text: "ҮС%",
        //   align: "start",
        //   sortable: true,
        //   value: "selectedDate",
        // },
      ];
    },
  },
  created() {
    if (this.selectedTeacher.id == this.userData.id) {
      this.editAllowed = true;
    }
  },
  watch: {},
  methods: {
    _getExamDate(exam) {
      if (exam["examDate-" + this.$attrs.lesson.id])
        return exam["examDate-" + this.$attrs.lesson.id];
      else if (
        exam["examDate-" + this.$attrs.lesson.classGroups[0].STUDENT_GROUP_ID]
      )
        return exam[
          "examDate-" + this.$attrs.lesson.classGroups[0].STUDENT_GROUP_ID
        ];
      else exam.examDate;
    },
    _getStudentNumber(exam) {
      if (exam["numberOfScoredStudents-" + this.$attrs.lesson.id]) {
        return exam["numberOfScoredStudents-" + this.$attrs.lesson.id];
      } else if (
        exam[
          "numberOfScoredStudents-" +
            this.$attrs.lesson.classGroups[0].STUDENT_GROUP_ID
        ]
      ) {
        return exam[
          "numberOfScoredStudents-" +
            this.$attrs.lesson.classGroups[0].STUDENT_GROUP_ID
        ];
      } else return exam.numberOfScoredStudents;
    },

    _confirmAll() {
      for (const stud of this.$attrs.students) {
        stud.score = 89;
        console.log(stud.score);
      }
    },
    _recoverScores() {
      this.$swal({
        title:
          "Эдгээр " +
          this.studentsTobeRecovery.length +
          " сурагчийн дүнг сэргээх үү?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          //var batch = fb.db.batch();
          if (this.studentsTobeRecovery) {
            for (const ss of this.studentsTobeRecovery) {
              console.log(ss);
              //batch.set(ss.ref);
            }
          }
          this.showRecoveryDialog = !this.showRecoveryDialog;
          // batch.commit().then(() => {
          //   this.showRecoveryDialog = !this.showRecoveryDialog;
          // });
        }
      });
    },
    _recoverStudent(exam) {
      console.log(exam);
      this.currentSelectedExam = exam;
      exam.ref.parent.parent
        .collection(
          "unelgee1-" + this.selectedTeacher.school.currentYear + "-scores"
        )
        .where(
          "STUDENT_GROUP_ID",
          "==",
          parseInt(this.$attrs.lesson.classGroups[0].classGroupRef.id)
        )
        .orderBy("FIRST_NAME", "asc")
        .get()
        .then((docs) => {
          this.studentsTobeRecovery = [];
          var counter = 0;
          docs.forEach((doc) => {
            counter++;
            let stud = doc.data();
            stud.id = doc.id;
            stud.ref = doc.ref;
            stud.index = counter;
            this.studentsTobeRecovery.push(stud);
          });
          this.showRecoveryDialog = !this.showRecoveryDialog;
        });
    },
    _getRemainGroups(studentsRemaind) {
      var remain = [
        ...new Set(
          studentsRemaind
            .filter((ss) => !ss.selectedGroup)
            .map((ss) => ss.STUDENT_GROUP_NAME)
        ),
      ];
      if (remain.length > 0) return remain;
      else return null;
    },
    _deleteAllScoreAndExam() {
      this.$swal({
        title:
          this.studentsTobeDelete.filter((ss) => ss.selectedGroup).length +
          " сурагчийн дүн устгагдана, та зөвшөөрөx үү?",

        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var batch = fb.db.batch();
          if (this.studentsTobeDelete) {
            for (const ss of this.studentsTobeDelete) {
              if (ss.lessonId == this.$attrs.lesson.id) {
                batch.delete(ss.ref);
              }
            }
          }
          batch.commit().then(() => {
            if (
              this.studentsTobeDelete.length ==
              this.studentsTobeDelete.filter((ss) => ss.selectedGroup).length
            ) {
              this.currentSelectedExam.ref.delete().then(() => {
                this.studentsTobeDelete = null;
                this.showUnelgeeDeleteDialog = !this.showUnelgeeDeleteDialog;
                console.log("done!!!");
              });
            } else {
              var tmp = {};
              tmp["scoreSummerizationInfo-" + this.$attrs.lesson.id] = {};
              tmp["numberOfScoredStudents-" + this.$attrs.lesson.id] = 0;
              this.currentSelectedExam.ref.update(tmp);

              this.studentsTobeDelete = null;
              this.showUnelgeeDeleteDialog = !this.showUnelgeeDeleteDialog;
            }
          });
        }
      });
    },
    _getSortedExams() {
      // return this.$attrs.examInfos.sort((a, b) => {
      //   const lowerA = a.examDate.toLowerCase();
      //   const lowerB = b.examDate.toLowerCase();
      //   if (lowerA < lowerB) return -1;
      //   if (lowerA > lowerB) return 1;
      //   return 0;
      // });
      return this.$attrs.examInfos;
    },
    _checkGroupInThisLesson(lessonGroup) {
      if (lessonGroup.ref.parent.parent == this.$attrs.lesson.ref)
        return lessonGroup.name;
      else return "Noooo";
    },
    _getChange(examItem, fieldName) {
      return this._getShortString(examItem[fieldName]);
    },
    _print(item) {
      console.log(item.ref.path);
      console.log(
        this.selectedTeacher.school.ref.path +
          "/unelgee1-" +
          this.selectedTeacher.school.currentYear +
          "/" +
          item.courseInfo.COURSE_ID
      );
    },
    _getShortString(str) {
      if (str && str.length < 40) return str;
      else return str ? str.substring(0, 20) + "..." : null;
    },

    _closeUnelgeeDialog() {
      this.unelgeeDialog = !this.unelgeeDialog;
    },

    _selectDate() {
      this.editedItem["examDate-" + this.$attrs.lesson.id] = this.selectedDate;
      // this.editedItem.examDate = this.selectedDate;
      this.showDateSelectDialog = !this.showDateSelectDialog;
    },
    _showSelectDateDialog() {
      this.selectedDate = this.editedItem.selectedDate
        ? this.editedItem.selectedDate
        : null;
      this.showDateSelectDialog = !this.showDateSelectDialog;
    },

    _newDialog(item) {
      this.newDialog = true;
      this.editedItem = {};
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    _deleteOK() {
      if (this.editedItem) {
        // this.editedItem.ref.delete();
        this.editedItem.ref.update({ deleted: true });
        this.editedItem = this.defaultItem;
      }
      this.deleteDialog = false;
    },
    _deleteItem(item) {
      this.currentSelectedExam = item;
      item.ref.parent.parent
        .collection(
          "unelgee1-" + this.selectedTeacher.school.currentYear + "-scores"
        )
        .where("examId", "==", item.id)

        .orderBy("FIRST_NAME", "asc")
        .get()
        .then((docs) => {
          this.studentsTobeDelete = [];
          var counter = 0;
          docs.forEach((doc) => {
            counter++;
            let stud = doc.data();
            stud.id = doc.id;
            stud.ref = doc.ref;
            if (stud.lessonId == this.$attrs.lesson.id) {
              stud.index = counter;
              stud.selectedGroup = true;
            }

            this.studentsTobeDelete.push(stud);
          });
          if (this.studentsTobeDelete.length == 0) {
            this.$swal({
              title: "Бүртгэгдсэн дүн байхгүй байна, устгаx уу?",
              text:
                (item[
                  "examDate-" +
                    this.$attrs.lesson.classGroups[0].STUDENT_GROUP_ID
                ]
                  ? item[
                      "examDate-" +
                        this.$attrs.lesson.classGroups[0].STUDENT_GROUP_ID
                    ]
                  : item.examDate) + " өдрийн үнэлгээний үзүүлэлт!",
              type: "warning",
              showCancelButton: true,
              customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
              },
              confirmButtonText: "Тийм",
              cancelButtonText: "Үгүй",
              reverseButtons: true,
            }).then((result) => {
              if (result.isConfirmed) {
                item.ref.delete();
              }
            });
          } else {
            this.showUnelgeeDeleteDialog = !this.showUnelgeeDeleteDialog;
          }
        });
    },
    _editItem(item, index) {
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
      this.editedItem.examConclusionBak = item.examConclusion
        ? item.examConclusion
        : null;
      this.examDateBak = item[
        "examDate-" + this.$attrs.lesson.classGroups[0].STUDENT_GROUP_ID
      ]
        ? item["examDate-" + this.$attrs.lesson.classGroups[0].STUDENT_GROUP_ID]
        : item.examDate
        ? item.examDate
        : null;
      this.examDescriptionBak = item.examDescription
        ? item.examDescription
        : null;

      this.selectTopic1 = this.editedItem.topic;
      this.selectTopic2 = this.editedItem.topics;
      this.newDialog = true;
    },
    _closeEdit() {
      this.newDialog = false;
      this.newBulkDialog = false;
      this.messageNotification = null;
      this.bulkText = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
        this.editedIndex = -1;
      });
    },
    _getExamScore(student, exam, lGroup) {
      if (this.$attrs.allScores) {
        if (lGroup) {
          var ss1 = this.$attrs.allScores.find(
            (score) =>
              score.examId == exam.id &&
              student.PERSON_ID == score.PERSON_ID &&
              score.lessonGroupId == lGroup.id
          );
          if (ss1) {
            return ss1;
          } else return null;
        } else {
          var ss = this.$attrs.allScores.find(
            (score) =>
              score.examId == exam.id && student.PERSON_ID == score.PERSON_ID
          );
          if (ss) {
            // console.log(student.firstName, student.ref.path, ss.score)
            return ss;
          } else return null;
        }
      }
    },
    _doUnelgee(item) {
      // console.log(this.$attrs.lesson);
      if (
        this.$attrs.lesson.classGroups &&
        this.$attrs.lesson.classGroups.length > 0
      ) {
        this.$attrs.lesson.ACADEMIC_LEVEL =
          this.$attrs.lesson.classGroups[0].ACADEMIC_LEVEL;
      }
      this.currentSelectedExam = item;
      if (this.$attrs.lesson.isGroupBased && !this.$attrs.lesson.mixed) {
        for (var lGroup of this.$attrs.lesson.lessonGroups) {
          lGroup.students.forEach((stud) => {
            var zz = this._getExamScore(stud, this.currentSelectedExam, lGroup);
            stud.score = zz ? zz.score : null;
            stud.description = zz ? zz.description : null;
            stud.scoreLevelNumber =
              zz && zz.scoreLevelNumber ? zz.scoreLevelNumber : null;
            stud.scoreLevelString =
              zz && zz.scoreLevelString ? zz.scoreLevelString : null;
          });
        }
      } else {
        this.$attrs.students.forEach((stud) => {
          var ss = this.$attrs.allScores.find(
            (sc) =>
              sc.examId == this.currentSelectedExam.id &&
              stud.PERSON_ID == sc.PERSON_ID
          );
          if (ss) {
            stud.score = ss.score;
            stud.description = ss.description ? ss.description : null;
            stud.scoreLevelNumber = ss.scoreLevelNumber
              ? ss.scoreLevelNumber
              : null;
            stud.scoreLevelString = ss.scoreLevelString
              ? ss.scoreLevelString
              : null;
          } else {
            stud.score = null;
            stud.description = null;
            stud.scoreLevelNumber = null;
            stud.scoreLevelString = null;
          }
        });
      }
      this.unelgeeDialog = !this.unelgeeDialog;
      // this.temporarlySelectedLesson = this.currentSelectedLesson;
      // this.currentSelectedClassGroup = this.temporarlySelectedLesson.classGroups[0];
      // this.newIrzDialog = !this.newIrzDialog;
      // console.log(this.temporarlySelectedLesson, this.temporarlySelectedLesson.classGroups[0]);
    },
    _saveUnelgee1OfStudents() {
      var numberOfScoredStudents = 0;
      var batch = fb.db.batch();
      //var batchStudent = fb.db.batch()
      this.savingUnelgee = true;
      var scoreSummerizationInfo = {
        numberOfLevel1: 0,
        numberOfLevel2: 0,
        numberOfLevel3: 0,
        numberOfLevel4: 0,
        numberOfLevel5: 0,
        numberOfLevel6: 0,
        numberOfLevel7: 0,
        numberOfLevel8: 0,
      };
      for (var student of this.$attrs.students) {
        var scoreUnelgee = Object.assign({}, student);

        scoreUnelgee["schoolId"] = this.selectedTeacher.school.id;
        scoreUnelgee["schoolName"] = this.selectedTeacher.school.name;
        scoreUnelgee["createdByRef"] = this.selectedTeacher.ref;
        scoreUnelgee["createdByName"] = this.selectedTeacher["DISPLAY_NAME"];
        scoreUnelgee["lesson"] = null;
        scoreUnelgee["lessonId"] = this.$attrs.lesson.id;
        scoreUnelgee["lessonRef"] = this.$attrs.lesson.ref;
        scoreUnelgee["courseInfo"] = this.$attrs.lesson.courseInfo;
        scoreUnelgee["semester"] = this.$attrs.semesterNumber;
        scoreUnelgee["year1"] = 1;
        scoreUnelgee["year"] = this.selectedTeacher.school.currentYear;
        scoreUnelgee["examId"] = this.currentSelectedExam.id;
        scoreUnelgee["examName"] = this.currentSelectedExam.examName;
        scoreUnelgee["examRef"] = this.currentSelectedExam.ref;
        scoreUnelgee["examDateOn"] = this._getExamDate(this.currentSelectedExam.examDate)
        scoreUnelgee["exam"] = null;
        scoreUnelgee["unelgeeId"] = 1;
        scoreUnelgee["createdAt"] = new Date();
        scoreUnelgee["scores"] = null;

        // var unelgeePath = this.selectedTeacher.school.ref.path+"/"+"courses-"+this.selectedTeacher.school.currentYear
        // + "/"+this.$attrs.lesson.courseInfo.COURSE_ID
        // +"/unelgee1-"+this.selectedTeacher.school.currentYear +"/" + this.currentSelectedExam.id
        // +"/scores"

        // var unelgeePath2 =
        //   this.selectedTeacher.school.ref.path +
        //   "/" +
        //   "unelgee1-" +
        //   this.selectedTeacher.school.currentYear +
        //   "/" +
        //   this.$attrs.lesson.courseInfo.COURSE_ID +
        //   "/unelgee1-" +
        //   this.selectedTeacher.school.currentYear +
        //   "-scores";

        // var unelgeeStudentPath =
        //   student.ref.path +
        //   "/" +
        //   +"/" +
        //   this.$attrs.lesson.courseInfo.COURSE_ID +
        //   "/student-unelgee1-" +
        //   this.selectedTeacher.school.currentYear;

        // console.log(unelgeeStudentPath+"/"+this.currentSelectedExam.id,student.firstName)
        if (
          scoreUnelgee.score != null &&
          scoreUnelgee.score != undefined &&
          typeof scoreUnelgee.score == "number"
        ) {
          numberOfScoredStudents++;
          // console.log(student.score)
          var docName1 = this.currentSelectedExam.id + "-" + student.id;
          // var docName2 = this.currentSelectedExam.id;

          var scoringRef = this.selectedTeacher.school.ref
            .collection("unelgee1-" + this.selectedTeacher.school.currentYear)
            .doc(this.$attrs.lesson.courseInfo.COURSE_ID.toString())
            .collection(
              "unelgee1-" + this.selectedTeacher.school.currentYear + "-scores"
            )
            .doc(docName1);

          // var studentScoringRef = student.ref
          //   .collection("unelgee1-" + this.selectedTeacher.school.currentYear)
          //   .doc(docName2);
          // console.log("scoreUnelgee", scoreUnelgee)
          if (scoreUnelgee.scoreLevelNumber == 1) {
            scoreSummerizationInfo.numberOfLevel1++;
          } else if (scoreUnelgee.scoreLevelNumber == 2) {
            scoreSummerizationInfo.numberOfLevel2++;
          } else if (scoreUnelgee.scoreLevelNumber == 3) {
            scoreSummerizationInfo.numberOfLevel3++;
          } else if (scoreUnelgee.scoreLevelNumber == 4) {
            scoreSummerizationInfo.numberOfLevel4++;
          } else if (scoreUnelgee.scoreLevelNumber == 5) {
            scoreSummerizationInfo.numberOfLevel5++;
          } else if (scoreUnelgee.scoreLevelNumber == 6) {
            scoreSummerizationInfo.numberOfLevel6++;
          } else if (scoreUnelgee.scoreLevelNumber == 7) {
            scoreSummerizationInfo.numberOfLevel7++;
          } else if (scoreUnelgee.scoreLevelNumber == 8) {
            scoreSummerizationInfo.numberOfLevel8++;
          }
          batch.set(scoringRef, scoreUnelgee, { merge: true });
          // batch.set(studentScoringRef, scoreUnelgee, { merge: true });
          // console.log(unelgeeStudentPath, "unelgeeStudentPath");
        }
      }

      batch.commit().then(() => {
        if (this.currentSelectedExam.classGroups)
          var tmp = {
            // numberOfScoredStudents: numberOfScoredStudents,
            // scoreSummerizationInfo: scoreSummerizationInfo,
          };
        tmp["numberOfScoredStudents-" + this.$attrs.lesson.id] =
          numberOfScoredStudents;
        tmp["scoreSummerizationInfo-" + this.$attrs.lesson.id] =
          scoreSummerizationInfo;

        // console.log("currentSelectedExam", this.currentSelectedExam.ref.path)
        this.currentSelectedExam.ref.update(tmp);
        this.savingUnelgee = false;
        this._closeUnelgeeDialog();
        for (var student of this.$attrs.students) {
          student.score = null;
        }
        this.$swal.fire(
          this.currentSelectedExam["examDate-" + this.$attrs.lesson.id]
            ? this.currentSelectedExam["examDate-" + this.$attrs.lesson.id]
            : this.currentSelectedExam.examDate + ": Амжилттай xадгалагдсан!"
        );
      });
      console.log(
        this.$attrs.lesson.courseInfo.COURSE_ID,
        this.$attrs.lesson.courseInfo
      );
    },
    _saveExam() {
      if (
        (this.editedItem.examTopic || this.editedItem.examName) &&
        (this.editedItem[
          "examDate-" + this.$attrs.lesson.classGroups[0].STUDENT_GROUP_ID
        ] ||
          this.editedItem["examDate-" + this.$attrs.lesson.id] ||
          this.editedItem.examDate)
      ) {
        if (this.editedItem.ref) {
          this.editedItem.updatedAt = new Date();
          this.editedItem["updatedByRef"] = this.selectedTeacher.ref;
          this.editedItem["updatedByName"] =
            this.selectedTeacher["DISPLAY_NAME"];

          this.editedItem[
            "examDate-" + this.$attrs.lesson.classGroups[0].STUDENT_GROUP_ID
          ] = this.editedItem["examDate-" + this.$attrs.lesson.id];

          this.editedItem.ref.update(this.editedItem).then(() => {
            console.log(
              this.editedItem,
              "editedItem",
              this.editedItem.ref.path
            );
            this._closeEdit();
          });
        } else {
          //new save
          this.editedItem["examDate"] =
            this.editedItem["examDate-" + this.$attrs.lesson.id];
          this.editedItem[
            "examDate-" + this.$attrs.lesson.classGroups[0].STUDENT_GROUP_ID
          ] = this.editedItem["examDate-" + this.$attrs.lesson.id];

          this.editedItem["schoolId"] = this.selectedTeacher.school.id;
          this.editedItem["schoolName"] = this.selectedTeacher.school.name;
          this.editedItem["createdByRef"] = this.selectedTeacher.ref;
          this.editedItem["createdByName"] =
            this.selectedTeacher["DISPLAY_NAME"];
          this.editedItem["lessonId"] = this.$attrs.lesson.id;
          this.editedItem["lessonRef"] = this.$attrs.lesson.ref;
          this.editedItem["classGroupIds"] = this.$attrs.lesson.classGroupIds;
          this.editedItem["classGroups"] = this.$attrs.lesson.classGroups;
          this.editedItem["classGroupRefs"] = this.$attrs.lesson.classGroupRefs;
          this.editedItem["courseInfo"] = this.$attrs.lesson.courseInfo;
          this.editedItem["semester"] = this.$attrs.semesterNumber;
          this.editedItem["year"] = this.selectedTeacher.school.currentYear;
          this.editedItem["newVersion24"] = true;
          //25132
          this.editedItem["esisLessonTypeId"] = this.$attrs.lesson.esisLessonType
            ? this.$attrs.lesson.esisLessonType.esisLessonTypeId
            : null;

          this.editedItem.createdAt = new Date();
          var docRef = this.selectedTeacher.school.ref
            .collection("unelgee1-" + this.selectedTeacher.school.currentYear)
            .doc(this.$attrs.lesson.courseInfo.COURSE_ID.toString())
            .collection("exams")
            .doc();
          console.log(this.editedItem, "editedItem", docRef.path);
          docRef.set(this.editedItem, { merge: true }).then(() => {
            this._closeEdit();
          });
        }
      } else {
        this.messageNotification =
          "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    _confirmUnelgee(unelgee, confirm) {
      if (confirm) {
        this.$swal({
          title: "Үнэлгээ 1 -ийг xаасанаа мэдэгдэх үү?",
          text: "Баталгаажуулсанаар таны дүн сурагчдад харагдана!",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            unelgee.ref.update({
              completed: true,
            });
          }
        });
      } else {
        this.$swal({
          title: "Дүн баталгаажсан. Дүнг буцаах уу?",
          text: "Тийм гэснээр дүн зөвхөн танд харагдана!",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            unelgee.ref.update({
              completed: false,
            });
          }
        });
      }
    },
    _getPath() {
      return this.selectedTeacher.school.currentYear;
    },
  },
};
</script>
