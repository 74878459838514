<template>
  <v-card class="px-0 pb-10">
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      top
      color="bg-gradient-success"
    >
      {{ actionstatus }}
      <template v-slot:action="{ attrs }">
        <v-btn
          class="font-weight-bold"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Xааx
        </v-btn>
      </template>
    </v-snackbar>
    <v-card-text class="px-4 py-0 scoreTabless mt-4 mb-10">
      <template v-if="loading == false">
        <template>
          <v-data-table
            v-if="xStudents"
            :headers="$attrs.examInfos"
            :items="xStudents"
            :items-per-page="-1"
            :search="search"
            hide-default-header
            hide-details
            hide-default-footer
          >
            <template v-slot:header="{ props: { headers } }">
              <tr>
                <th style="width: 1%">No.</th>
                <th style="width: 15%">Нэр</th>
                <th
                  v-if="
                    $attrs.lesson.esisLessonType &&
                    $attrs.lesson.esisLessonType.esisLessonTypeId == 3
                  "
                  style="width: 5%"
                >
                  Бүлэг
                </th>
                <th
                  @click="_printExam(examInfo)"
                  v-for="examInfo in headers"
                  :key="examInfo.id"
                  class="text-center"
                >
                  {{ _getShortExamName(examInfo) }}
                  <p class="blue--text">
                    {{ _getExamDate(examInfo) }}
                  </p>
                </th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th
                  v-if="
                    $attrs.lesson.esisLessonType &&
                    $attrs.lesson.esisLessonType.esisLessonTypeId == 3
                  "
                ></th>
                <th
                  v-for="examInfo in headers"
                  :key="examInfo.id"
                  class="text-center"
                >
                  <p class="mb-0">
                    <span v-for="iIndex in [1, 2, 3, 4]" :key="'lvel' + iIndex">
                      <span class="font-weight-normal">
                        {{ romanNumbers[iIndex - 1] }}</span
                      >:
                      {{
                        _getSummerizationLevelInfo(examInfo, [
                          "numberOfLevel" + iIndex,
                        ])
                      }}</span
                    >
                  </p>

                  <p class="mb-0">
                    <span v-for="iIndex in [5, 6, 7, 8]" :key="'lvel' + iIndex">
                      <span class="font-weight-normal">
                        {{ romanNumbers[iIndex - 1] }}</span
                      >:
                      {{
                        _getSummerizationLevelInfo(examInfo, [
                          "numberOfLevel" + iIndex,
                        ])
                      }}</span
                    >
                  </p>
                </th>
              </tr>
            </template>

            <template slot="item" slot-scope="props">
              <tr>
                <td style="width: 1%" class="pl-1" @click="_print(props.item)">
                  {{ props.item.numbering }}
                </td>
                <td class="pl-1">
                  {{
                    props.item["LAST_NAME"][0] + ". " + props.item["FIRST_NAME"]
                  }}
                  <span class="grey--text">
                    /{{ props.item.DATE_OF_BIRTH.replace("T00:00:00.000Z", "") }}/</span
                  >
                </td>
                <td
                  style="width: 5%"
                  class="blue--text"
                  v-if="
                    $attrs.lesson.esisLessonType &&
                    $attrs.lesson.esisLessonType.esisLessonTypeId == 3
                  "
                >
                  {{ props.item.STUDENT_GROUP_NAME }}
                </td>
                <td
                  @click="_deleteScore(props.item, exam)"
                  v-for="exam in $attrs.examInfos"
                  :key="'exam-' + exam.id"
                  style="width: 3%; cursor: pointer"
                  class="text-center pl-0"
                >
                  <v-row no-gutters>
                    <v-col
                      class="pa-0"
                      style="
                        height: 25px;
                        padding-top: 2px !important;
                        border-right: 2px solid #dddddd;
                      "
                      >{{ _getExamScore(props.item, exam) }}</v-col
                    >
                    <v-col
                      class="pa-0"
                      style="height: 25px; padding-top: 2px !important"
                      >{{ _getExamScore2(props.item, exam) }}</v-col
                    >
                  </v-row>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </template>
      <div v-else>
        <v-card height="400">
          <v-progress-linear
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  name: "light-table-transparent",
  data() {
    return {
      romanNumbers: ["I", "II", "III", "IV", "V", "VI", "VII", "VIII"],
      currentSelectedExam: null,
      actionstatus: null,
      search: "",
      snackbar: false,
      headers2: [
        {
          text: "No",
          align: "end",
          value: "index",
          sortable: true,
          fixed: true,
        },
        {
          text: "Нэр",
          align: "start",
          value: "firstName",
          sortable: true,
          fixed: true,
        },
      ],
      loading: false,
    };
  },
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
    xStudents() {
      var list = [];
      var counter = 0;
      if (this.$attrs.students) {
        for (const stud of this.$attrs.students) {
          if(!stud.ACTION_DATE) {
            counter++;
            stud.numbering = counter;
            list.push(stud);
          }
          else if (this._isMovementAllowed(stud)) {
            counter++;
            stud.numbering = counter;
            list.push(stud);
          }
        }
      }
      return list;
    },
    selectedTeacher() {
      return this.teacher;
    },
  },
  props: {
    teacher: {
      type: Object,
    },
  },

  methods: {
    _isMovementAllowed(student) {
      var found = this.$store.state.calendarButez2.find(
        (ss) => ss.session == this.$attrs.semesterNumber
      );
      if (found) {
        var dd = new Date(student.ACTION_DATE);
        return dd <= new Date(found.endOfSession);
      }
      return null;
      // return found;
      // new Date(props.item.ACTION_DATE) > new Date("2025-01-01")
    },
    _printExam(ex) {
      console.log(ex.ref.path);
    },
    _getExamDate(exam) {
      if (exam["examDate-" + this.$attrs.lesson.id])
        return exam["examDate-" + this.$attrs.lesson.id];
      else if (
        exam["examDate-" + this.$attrs.lesson.classGroups[0].STUDENT_GROUP_ID]
      )
        return (
          exam[
            "examDate-" + this.$attrs.lesson.classGroups[0].STUDENT_GROUP_ID
          ] + "gggg"
        );
      else return exam.examDate;
    },
    _getSummerizationLevelInfo(exam, level) {
      //todo121
      if (
        exam["scoreSummerizationInfo-" + this.$attrs.lesson.id] &&
        exam["scoreSummerizationInfo-" + this.$attrs.lesson.id][level]
      ) {
        return exam["scoreSummerizationInfo-" + this.$attrs.lesson.id][level];
      }

      // else if (
      //   exam[
      //     "scoreSummerizationInfo-" +
      //       this.$attrs.lesson.classGroups[0].STUDENT_GROUP_ID
      //   ] &&
      //   exam[
      //     "scoreSummerizationInfo-" +
      //       this.$attrs.lesson.classGroups[0].STUDENT_GROUP_ID
      //   ][level]
      // ) {
      //   return exam[
      //     "scoreSummerizationInfo-" +
      //       this.$attrs.lesson.classGroups[0].STUDENT_GROUP_ID
      //   ][level];
      // } else if (exam.scoreSummerizationInfo)
      //   return exam.scoreSummerizationInfo[level];
      // else return 0;
    },
    _getFilteredGroupHeader(examInfos, lg) {
      var list = [];
      for (const exam of examInfos) {
        if (exam.lessonGroup && exam.lessonGroup.id == lg.id) {
          list.push(exam);
        } else if (!exam.lessonGroup) {
          list.push(exam);
        }
      }
      return list;
    },
    _doUnelgee(item) {
      this.currentSelectedExam = item;
      console.log(this.$attrs.allScores);
      this.$attrs.students.forEach((stud) => {
        var ss = this.$attrs.allScores.find(
          (sc) =>
            sc.examId == this.currentSelectedExam.id &&
            stud.PERSON_ID == sc.PERSON_ID
        );
        console.log(ss);
        if (ss) stud.score = ss.score;
        else stud.score = null;
      });
      this.unelgeeDialog = !this.unelgeeDialog;
    },
    _printScore(student, exam, lg) {
      var ss = this.$attrs.allScores.find(
        (score) =>
          score.examId == exam.id && student.PERSON_ID == score.PERSON_ID
      );
      console.log(student.scores, exam.id, exam.ref.path, ss.ref.path);
      if (lg) {
        console.log(this._getExamScore(student, exam, lg));
      }
    },

    _deleteScore(student, exam) {
      var ss = this.$attrs.allScores.find(
        (score) =>
          score.examId == exam.id && student.PERSON_ID == score.PERSON_ID
      );
      console.log(exam);
      console.log(exam.ref.path, ss.ref.path);
      if (ss) {
        this.$swal({
          title:
            student.FIRST_NAME +
            ", " +
            student.LAST_NAME +
            " сурагчийн дүнг устгаx уу?",
          text: exam.examName + ", Дүн: " + ss.score,
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            ss.ref.delete();
          }
        });
      }
    },

    _print(item) {
      console.log(item.ref.path);
    },

    _getExamScore2(student, exam, lGroup) {
      if (this.$attrs.allScores) {
        if (lGroup) {
          var ss1 = this.$attrs.allScores.find(
            (score) =>
              score.examId == exam.id &&
              student.PERSON_ID == score.PERSON_ID &&
              score.lessonGroupId == lGroup.id
          );
          if (ss1) {
            return ss1.scoreLevelString;
          } else return null;
        } else {
          var ss = this.$attrs.allScores.find(
            (score) =>
              score.examId == exam.id && student.PERSON_ID == score.PERSON_ID
          );
          if (ss) {
            // console.log(student.firstName, student.ref.path, ss.score)
            return ss.scoreLevelString;
          } else return null;
        }
      }
    },
    _getExamScore(student, exam, lGroup) {
      if (this.$attrs.allScores) {
        if (lGroup) {
          //removeit //TODO2025
          var ss1 = this.$attrs.allScores.find(
            (score) =>
              score.examId == exam.id &&
              student.PERSON_ID == score.PERSON_ID &&
              score.lessonGroupId == lGroup.id
          );
          if (ss1) {
            return ss1.score;
          } else return null;
        } else {
          var ss = this.$attrs.allScores.find(
            (score) =>
              score.examId == exam.id && student.PERSON_ID == score.PERSON_ID
          );
          if (ss) {
            // console.log(student.firstName, student.ref.path, ss.score);
            return ss.score;
          } else return null;
        }
      }
    },
    _getShortExamName(examInfo) {
      var name = null;
      if (examInfo.examTopic) name = examInfo.examTopic.parentCourseTopicName;
      else name = examInfo.examName ? examInfo.examName : null;
      if (name) {
        if (name.length < 40) return name;
        else return name.substring(0, 40) + " ...";
      }
      return name;
    },
    // async finalScoreChanged(student, semesterNumber) {
    //   console.log(student.ref.path, this.lesson.ref);
    //   this.lesson.ref
    //     .collection("studentList")
    //     .doc(student.id)
    //     .set({ scores: student.scores }, { merge: true })
    //     .then(() => {
    //       // student.ref.collection("finalGrades").doc(this.lesson.id).set(
    //       //   {
    //       //     scores: student.scores,
    //       //     semester: 1
    //       //   },
    //       //   { merge: true }
    //       // );

    //       this.actionstatus =
    //         student.firstName +
    //         ", " +
    //         student.scores[semesterNumber - 1].finalScore +
    //         " дүн амжилттай xадгалагдлаа.";
    //       this.snackbar = true;
    //       document.getElementById(student.id + semesterNumber).blur();
    //     });
    // },
  },
  async created() {
    // if (!this.selectedTeacher) this.selectedTeacher = this.userData;
    this.headers2 = this.$attrs.examInfos;
  },
};
</script>
<style>
.scoreTabless td,
.scoreTabless th {
  padding: 0px !important;
  padding-left: 5px !important;
  border: 1px solid #dddddd;
  text-align: left;
  height: 25px !important;
}
</style>
