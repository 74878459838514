<template>
  <v-app>
    <v-container class="my-6 mx-0" fluid>
      <v-card class="px-0 py-5">
        <v-row justify="space-between" class="ma-0 mx-3">
          <v-col>
            <h5
              class="font-weight-bold text-h5 text-typo mb-0"
              @click="_print(currentSelectedLesson)"
            >
              Үнэлгээ I  
              <!-- - <span v-if="currentSelectedLesson">{{ currentSelectedLesson.id }}</span> -->
            </h5>
          </v-col>
          <!-- <v-btn @click="_findSemester">dksfjsdfjs</v-btn> -->
        </v-row>
        <v-row class="ma-0 mx-6">
          <v-col md="8" lg="8" cols="8" class="pa-0">
            <v-select
              return-object
              v-model="currentSelectedLesson"
              :items="filteredLessons"
              item-text="name3"
              item-value="id"
              clearable
              label="Xичээл сонгоx"
            >
              <template #item="{ item }">
                <v-row>
                  <v-col lg="8" md="8" sm="8" cols="8">
                    <span class="text-typo"
                      >{{ item.name3 }}
                      <span
                        style="color: #d81b60"
                        v-if="
                          item.esisLessonType &&
                          item.esisLessonType.esisLessonTypeId > 1
                        "
                      >
                        - {{ item.esisLessonType.name }}</span
                      >
                      <span
                        style="color: #d81b60"
                        v-if="item.selectedGroupName"
                      >
                        - ({{ item.selectedGroupName }})</span
                      >
                    </span>
                  </v-col>
                  <v-col class="text-end">
                    <span class="blue--text">
                      {{ _getLessonClassGroupsName(item) }}</span
                    >
                  </v-col>
                </v-row>
              </template>
              <template v-slot:selection="{ item }">
                {{ item.name3 }} -
                <span class="text-typo mr-4">
                  <span
                    style="color: #d81b60"
                    v-if="
                      item.esisLessonType &&
                      item.esisLessonType.esisLessonTypeId > 1
                    "
                  >
                    {{ item.esisLessonType.name }}
                    <span style="color: #d81b60" v-if="item.selectedGroupName">
                      ({{ item.selectedGroupName }})</span
                    >
                  </span>
                  <span v-else-if="item.esisLessonType" class="blue--text">
                    {{ item.esisLessonType.name }}</span
                  >
                </span>
                {{ _getLessonClassGroupsName(item) }}
              </template>
            </v-select>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="pa-0">
            <v-select
              label="Улирал сонгоx"
              v-model="currentSelectedSemester"
              :items="$store.state.yearSemesters"
            >
            </v-select>
          </v-col>
          <!-- <v-col md="2" lg="2" cols="2" class="pa-0 ml-2" v-if="currentSelectedLesson&&currentSelectedLesson.esisLessonType.esisLessonTypeId > 1">
            <v-select :items="currentSelectedLesson.lessonGroups" v-model="selectedGroup" label="Group" return-object
              item-text="name" item-value="id">
            </v-select>
          </v-col> -->
        </v-row>
        <div v-if="currentSelectedLesson && renderComponent">
          <template
            v-if="
              currentSelectedLesson.esisLessonType &&
              currentSelectedLesson.esisLessonType.esisLessonTypeId >= 1
            "
          >
            <!-- <p>{{ currentSelectedLesson.isGroupBased }} - {{ currentSelectedLesson.esisLessonType.esisLessonTypeId }}</p> -->
            <Unelgee1Widget
              v-if="loading == false && students && students.length > 0"
              :lesson="currentSelectedLesson"
              :students="students"
              :semesterNumber="currentSelectedSemester"
              v-bind="$attrs"
              :selectedGroup="selectedGroup"
              :teacher="selectedTeacher"
            ></Unelgee1Widget>
            <v-card height="400" v-else>
              <v-progress-linear
                v-if="slowedLoading"
                color="red"
                height="6"
                indeterminate
              ></v-progress-linear>
              <div
                v-else-if="students && students.length == 0"
                class="text-center mt-16"
              >
                <v-card-text style="width: 600px">
                  <v-alert
                    color="#F8BBD0"
                    class="mt-4"
                    text
                    prominent
                    icon="mdi-check-circle-outline"
                  >
                    <h3 style="color: #d81b60">
                      Хичээл сурагчгүй байна. Сурагчаа бүргэнэ үү!
                    </h3>
                  </v-alert>
                </v-card-text>
              </div>
            </v-card>
          </template>
          <template v-else>
            <v-container>
              <h1 class="red--text">
                Засвар хийгдэж байна. 12/12 ны 15 цагаас дахин нээгдэнэ.
              </h1>
              <p class="red--text">
                Үндсэн хичээлийн үнэлгээгээ хийж байна уу.
              </p>
            </v-container>
          </template>
        </div>
        <div v-else>
          <v-card class="text-center mt-10 px-10">
            <h2 style="color: #d81b60">Хичээлээ сонгоно уу!</h2>
            <v-alert
              color="#F8BBD0"
              class="mt-10"
              text
              prominent
              icon="mdi-check-circle-outline"
            >
              <h3 style="color: #d81b60; font-weight: normal">
                Багш нараа, та бүxэн үнэлгээ1 дүнгээ оруулаарай.
              </h3>
            </v-alert>
          </v-card>
        </div>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import Unelgee1Widget from "./Unelgee1Widget.vue";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);

export default {
  name: "PageLessonAttendanceChecking",
  components: {
    Unelgee1Widget,
  },
  props: {
    teacher: {
      type: Object,
    },
  },
  data: () => ({
    selectedTeacher: null,
    selectedSemester: 1,
    selectedGroup: null,
    currentSelectedSemester: null,
    currentSelectedLesson: null,
    loading: false,
    lessons: null,
    renderComponent: true,
    students: null,
    slowedLoading: false,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    filteredLessons() {
      var list = [];
      var counter = 0;
      if (this.lessons) {
        for (var ll of this.lessons) {
          if (
            ll.esisLessonType &&
            ll.esisLessonType.esisLessonTypeId > 2 &&
            ll.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE"
          ) {
            var parentLL = null;

            counter++;
            parentLL = ll;
            parentLL.index = counter;
            parentLL.uniqueId = ll.id + counter;
            parentLL.name3 = counter + ". " + ll.name2;
            parentLL.subLessons = [ll];
            console.log(ll);
            parentLL.isCollected = true;
            list.push(parentLL);
          } else {
            counter++;
            ll.index = counter;
            ll.uniqueId = ll.id + counter;
            ll.name3 = counter + ". " + ll.name2;
            list.push(ll);
          }
        }
      }
      return list;
    },
  },
  watch: {
    
    loading(val) {
      if (val) {
        this.slowedLoading = true;
      } else {
        setTimeout(() => {
          this.slowedLoading = false;
        }, 1000);
      }
    },
    currentSelectedSemester() {
      this.forceRerender();
    },
    async currentSelectedLesson(lesson) {
      // console.log(lesson);
      console.log(lesson.ref.path);
      if (lesson) {
        if (
          lesson &&
          lesson.esisLessonType &&
          lesson.esisLessonType.esisLessonTypeId == 1
        ) {
          this.loading = true;
          this.selectedTeacher.school.ref
            .collection("students-" + this.selectedTeacher.school.currentYear)
            .where(
              "STUDENT_GROUP_ID",
              "==",
              Number(lesson.classGroupRefs[0].id)
            )
            .orderBy("FIRST_NAME", "asc")
            .get()
            .then((docs) => {
              this.students = [];
              docs.forEach((doc) => {
                let student = doc.data();
                student.ref = doc.ref;
                student.id = doc.id;
                // console.log(student)
                if (!student.moved) {
                  this.students.push(student);
                }
              });
              this.forceRerender();
              this.loading = false;
            });
        } else {
          this.loading = true;
          lesson.ref
            .collection("students-" + this.currentSelectedSemester)
            .orderBy("FIRST_NAME", "asc")
            .get()
            .then((docs) => {
              this.students = [];
              docs.forEach((doc) => {
                let student = doc.data();
                student.ref = doc.ref;
                student.id = doc.id;
                if (!student.moved) {
                  this.students.push(student);
                }
              });
              this.loading = false;
            });
        }
      }
    },
  },
  methods: {
    _findSemester() {
      console.log(this._findSemester0("2023", "9", "2"));
      console.log("------");
      console.log(this._findSemester0("2024", "5", "1"));
      console.log("------");
      console.log(this._findSemester0("2024", "1", "29"));
    },
    _findSemester0(year, month, day) {
      var tmpDate = new Date(year, month - 1, day);
      console.log(tmpDate);
      for (const sem of this.$store.state.calendarButez2) {
        var startDate = new Date(
          sem.duration[0].year,
          sem.duration[0].month - 1,
          sem.duration[0].day
        );
        var endDate = new Date(
          sem.duration[1].year,
          sem.duration[1].month - 1,
          sem.duration[1].day
        );
        // var more = new Date(startDate.year, startDate.month,startDate.day)<=tmpDate
        // var less = tmpDate <= new Date(endDate.year, endDate.month,endDate.day)

        console.log(
          sem.session,
          startDate,
          endDate,
          startDate <= tmpDate,
          endDate >= tmpDate
        );

        // if(new Date(startDate.year, startDate.month,startDate.day)<=tmpDate&&tmpDate<=endDate)
        //   return sem
      } //for
      return null;
    },
    async findGroupStudentsFromDifferentClasses(editing) {
      console.log(this.temporarlySelectedLesson);
      this.loading = true;
      if (this.temporarlySelectedLesson.subLessons) {
        this.students = [];
        this.selectedLessonGroups = [];
        for (const sll of this.temporarlySelectedLesson.subLessons) {
          console.log(sll.classGroups[0].classGroupName, sll.ref.path);
          sll.ref
            .collection("lesson-groups")
            .where("teacher.teacherId", "==", this.selectedTeacher.id)
            .get()
            .then((docs) => {
              docs.forEach(async (doc) => {
                let gg = doc.data();
                gg.id = doc.id;
                gg.ref = doc.ref;

                await gg.ref
                  .collection("students")
                  .orderBy("FIRST_NAME", "asc")
                  .get()
                  .then((docs) => {
                    docs.forEach((doc) => {
                      let student = doc.data();
                      student.id = doc.id;
                      student.ref = doc.ref;
                      student.classGroup = sll.classGroups[0];
                      student.classGroupRef = sll.classGroups[0].classGroupRef;
                      student.lessonGroup = gg;
                      student.attendances = null;
                      student.studentsNotes = null;
                      student.requests = null;
                      sll.classGroupRefs[0]
                        .collection(
                          "attendances-" +
                            this.selectedTeacher.school.currentYear
                        )
                        .where("studentId", "==", student.id)
                        .onSnapshot((docs) => {
                          student.attendances = [];
                          student.studentsNotes = [];
                          if (!docs.empty) {
                            docs.forEach((doc) => {
                              if (doc.exists) {
                                let x = doc.data();
                                x.ref = doc.ref;
                                x.id = doc.id;
                                if (x.id.startsWith("studentsNotes"))
                                  student.studentsNotes.push(x);
                                else student.attendances.push(x);
                              }
                            });
                          }
                        });
                      this.students.push(student);
                    });
                  });
                this.selectedLessonGroups.push(gg);
              });
            });
        }
        if (editing) {
          console.log(this.selectedLessonGroups);
          this.irzBurtgehDialog = true;
        }
      }
      this.loading = false;
    },
    _print(lesson) {
      if (lesson.ref) console.log(lesson.ref.path, lesson);
      console.log(
        this.selectedTeacher.school.ref.path +
          "/courses-" +
          this.selectedTeacher.school.currentYear +
          "/" +
          lesson.courseInfo.COURSE_ID
      );
    },
    _getLessonClassGroupsName(lesson) {
      var str = null;
      if (lesson.classGroups) {
        for (const gg of lesson.classGroups) {
          str = str ? str + ", " + gg.classGroupName : gg.classGroupName;
        }
      }

      return str;
    },
    async forceRerender() {
      this.renderComponent = false;
      console.log(this.renderComponent);
      await this.$nextTick();
      this.renderComponent = true;
    },
    async _setupp() {
      this.currentSelectedSemester = this.$store.state.schoolCurrentSemester;
      if (this.selectedTeacher.school) {
        var query = this.selectedTeacher.school.ref
          .collection("lessons-" + this.selectedTeacher.school.currentYear)
          .where("deleted", "==", false);

        if (this.selectedTeacher.role == "teacher") {
          query = query.where(
            "teacherRefs",
            "array-contains",
            this.selectedTeacher.ref
          );
        }
        query = query.orderBy("courseInfo.COURSE_NAME", "desc");

        query.onSnapshot((docs) => {
          this.lessons = [];
          var counter = 0;
          docs.forEach((doc) => {
            counter++;
            let lesson = doc.data();
            lesson.id = doc.id;
            lesson.ref = doc.ref;
            lesson.topics = null;
            this.isAllowedLesson(lesson);
            if (!lesson.isNotAllowed) {
              lesson.name2 = lesson.courseInfo.COURSE_NAME;
              lesson.name3 = counter + ". " + lesson.courseInfo.COURSE_NAME;
              if (
                lesson &&
                lesson.esisLessonType &&
                lesson.esisLessonType.esisLessonTypeId == 1
              ) {
                lesson.isGroupBased = false;
              }
              this.lessons.push(lesson);
            }
          });
        });
      }
    },
    isAllowedLesson(lesson) {
      if (lesson && lesson.classGroupRefs) {
        for (var ll of lesson.classGroupRefs) {
          if (ll.path.includes("/departments/")) {
            lesson.isNotAllowed = true;
            break;
          }
        }
      }
    },
  },
  created() {
    if (this.teacher) this.selectedTeacher = this.teacher;
    else this.selectedTeacher = this.userData;
    this._setupp();
  },
};
</script>
