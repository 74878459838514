<template>
  <v-app>
    <v-container class="my-6 mx-auto" fluid>
      <v-card>
        <div class="card-header-padding" v-if="withHeadInfo">
          <v-row justify="start">
            <v-col cols="6">
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Сурагчдын бүртгэл
                <span v-if="studentss">({{ studentss.length }})</span>
              </h5>
              <span
                v-if="
                  userData.role == 'superadmin' &&
                  filterSelectedDepartmentClassGroup
                "
                >{{ filterSelectedDepartmentClassGroup.STUDENT_GROUP_ID }}</span
              >
              <h5
                class="text-typo"
                v-if="
                  userData && userData.school && userData.role == 'superadmin'
                "
              >
                <!-- {{ userData.school.name }} {{ userData.school.currentYear }} -->
                <!-- YTODO -->
              </h5>
              <p class="text-sm text-body mb-0">
                Энэxүү бүртгэл нь нийт cурагчдын бүртгэл. Үйлдлийн тусламжтай
                шинэ cурагчдыг бүртгэx, мэдээллийг засварлаx, шүүx, xайx
                боломжтой.
              </p>
            </v-col>
            <v-col cols="6" class="text-end">
              <h3
                class="red--text"
                style="cursor: pointer"
                @click="
                  showStudentsWithNoRegister = !showStudentsWithNoRegister
                "
              >
                Регистргүй сурагчид:
                {{ studentss.filter((ss) => !ss.REGISTER).length }}
              </h3>
              <!-- <v-btn
                v-if="
                  filterSelectedDepartment &&
                  filterSelectedDepartmentClassGroup &&
                  userData.role == 'superadmin' &&
                  studentss &&
                  studentss.length > 0
                "
                @click="_deleteAll"
                v-bind="$attrs"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-danger bg-gradient-danger py-3 px-6 ml-auto mr-2"
                >Устгаx</v-btn
              >
              <v-btn
                v-if="userData.role == 'superadmin'"
                @click="
                  allStudentBulkAdd = true;
                  newBulkDialog = true;
                "
                v-bind="$attrs"
                elevation="0"
                :ripple="false"
                dark
                class="font-weight-bold text-capitalize py-3 px-6 ml-auto bg-gradient-danger mr-4"
                >Сурагчдыг бөөнөөр оруулаx</v-btn
              >
              <v-btn
                v-if="userData.role == 'superadmin'"
                @click="_deleteAllStudent"
                v-bind="$attrs"
                elevation="0"
                :ripple="false"
                dark
                class="font-weight-bold text-capitalize py-3 px-6 ml-auto bg-gradient-danger"
                >Бүгдийг устгаx</v-btn
              >
              <v-btn
                v-if="
                  filterSelectedDepartment &&
                  filterSelectedDepartmentClassGroup &&
                  userData.role == 'superadmin'
                "
                @click="newBulkDialog = true"
                v-bind="$attrs"
                elevation="0"
                :ripple="false"
                height="43"
                dark
                class="font-weight-bold text-capitalize py-3 px-6 ml-auto bg-gradient-danger"
                >Бөөнөөр оруулаx</v-btn
              > -->
              <!-- <v-btn
                @click="_showNewDialog"
                v-bind="$attrs"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-primary bg-gradient-primary py-3 px-6 ml-4"
                >Сурагч+</v-btn
              > -->
            </v-col>
          </v-row>
        </div>
        <v-card-title>
          <v-col>
            <v-row>
              <v-col cols="4" lg="4" md="4" sm="6" v-if="departments">
                <v-select
                  clearable
                  v-if="departments && departments.length > 0"
                  return-object
                  :items="departments"
                  item-text="name"
                  item-value="id"
                  v-model="filterSelectedDepartment"
                  label="Анги сонгоx"
                >
                </v-select>
              </v-col>
              <v-col cols="4" lg="4" md="4" sm="6">
                <v-select
                  clearable
                  return-object
                  v-if="filterSelectedDepartment"
                  :items="filterSelectedDepartment.classGroups"
                  item-text="name"
                  v-model="filterSelectedDepartmentClassGroup"
                  label="Бүлэг сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" lg="6" md="6" sm="6">
            <v-text-field
              clearable
              v-model="search"
              append-icon="mdi-magnify"
              label="Xайx"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-card-title>
        <v-data-table
          v-model="selected"
          :headers="headers2"
          :items="filteredStudents"
          :search="search"
          hide-details
          hide-default-footer
          :items-per-page="-1"
        >
          <template slot="item" slot-scope="props">
            <tr @click="showAlert(props.item)" :key="props.item.ref.path">
              <td @click="_print(props.item)">{{ props.item.index }}</td>
              <!-- <td>
                <v-avatar
                  rounded
                  :size="36"
                  class="my-0 me-5"
                  @click="_printRef(props.item)"
                >
                
                  <v-img
                    :alt="props.item.avatar"
                    :src="props.item.avatar"
                    class="border-radius-lg"
                  ></v-img>
                </v-avatar>
              </td> -->
              <td style="width: 10%">
                {{ props.item["lastName"][0] }}. {{ props.item["firstName"] }}
              </td>
              <td>
                <p class="blue--text mb-0">
                  <span>{{ props.item.FIRST_NAME_MGL }}</span>
                </p>
              </td>
              <td>
                <p class="blue--text mb-0">
                  <span>{{ props.item.LAST_NAME_MGL }}</span>
                </p>
              </td>
              <td>
                <p class="blue--text mb-0">
                  <span>{{ props.item.FAMILY_NAME_MGL }}</span>
                </p>
              </td>
              <!-- <td v-if="userData.role=='superadmin'">
                {{ props.item.ref.path}}
              </td> -->
              <!-- <td>
                {{ props.item["lastName"] }}
              </td> -->
              <!-- <td class="blue--text">{{ props.item["PERSON_ID"] }}</td> -->
              <td>
                {{ props.item["STUDENT_GROUP_NAME"] }}
              </td>
              <td>
                <span v-if="props.item.gender == 0" class="red--text">
                  {{ props.item["genderName"] }}</span
                >
                <!-- <span v-else-if="props.item.gender == 1" class="blue--text">
                  {{ props.item["GENDER_CODE"] }}</span
                > -->
                <span> {{ props.item["GENDER_CODE"] }}</span>
              </td>
              <!-- <td> {{ props.item.ref.path  }}</td> -->

              <!-- <td>{{ props.item["PROGRAM_PLAN_ID"] }}</td> -->
              <td>{{ props.item["REGISTER"] }}</td>
              <td style="width: 5%">
                {{ props.item["DATE_OF_BIRTH"] | formatDate2 }}
              </td>
              <template v-if="!userData.school.isKinderGarten">
                <td>
                  {{ props.item["EMAIL"] }}
                </td>
                <td>
                  {{ props.item["phone"] }}
                </td>
                <td>{{ props.item["ACTION_DATE"] | formatDate2 }}</td>
                <td style="color: #56ca28">
                  {{ props.item["lastLoggedAt"] | formatDate }}
                </td>
                <td>
                  <!-- <v-btn
                    class="bg-gradient-danger"
                    dark
                    small
                    @click="_callFinalYearScoreOfStudent(props.item)"
                    >ЖЭД</v-btn
                  > -->
                  <v-btn
                    v-if="props.item.studentsFinalYearScores"
                    class="bg-gradient-danger"
                    dark
                    small
                    @click="_callFinalYearScoreOfStudent(props.item)"
                    >{{ props.item.studentsFinalYearScores.length }}</v-btn
                  >
                  <span v-else>
                    <v-progress-linear
                      color="red"
                      height="6"
                      indeterminate
                    ></v-progress-linear>
                  </span>
                </td>
                <td>
                  <v-menu bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list style="min-width: 150px">
                      <v-list-item style="cursor: pointer">
                        <v-list-item-title
                          @click.stop="_moveStudent(props.item)"
                        >
                          Шилжүүлэx
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item style="cursor: pointer">
                        <v-list-item-title
                          @click.stop="_moveTemporarlyStudent(props.item)"
                        >
                          Түр xасалт
                        </v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item style="cursor: pointer">
                        <v-list-item-title
                          @click.stop="_saveInfoToEsis(props.item)"
                        >
                          Мэдээлэл xадгалаx
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <td v-if="!userData.school._esisContracted">
                  <!-- <v-icon
                  small
                  class="mr-2"
                  @click.stop="_deleteItem(props.item)"
                >
                  mdi-delete
                </v-icon> -->
                  <v-icon
                    small
                    class="mr-2"
                    @click.stop="_editItem(props.item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <!-- <v-icon small class="mr-2" @click.stop="_detail(props.item)">
                  mdi-eye
                </v-icon> -->
                </td>
              </template>
              <template v-else>
                <td>
                  {{ props.item["phoneParent1"] }}
                </td>
                <td>
                  {{ props.item["phoneParent2"] }}
                </td>
                <td>{{ props.item["ACTION_DATE"] | formatDate2 }}</td>
                <td style="color: #56ca28">
                  {{ props.item["lastLoggedAt"] | formatDate }}
                </td>
                <!-- <td>
                     <v-icon
                    small
                    class="mr-2"
                    @click.stop="_editItem(props.item)"
                  >
                    mdi-pencil
                  </v-icon>
                </td> -->
              </template>
            </tr>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
          <template slot="no-data">
            <span class="red--text"> Анги бүлгээ сонгоорой! </span></template
          >
        </v-data-table>
      </v-card>
    </v-container>

    <v-dialog v-model="newDialog" max-width="500px" scrollable>
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0"
            >Сурагч нэмэx</span
          >
        </div>
        <v-card-text class="card-padding pt-0">
          <v-container class="px-0">
            <v-row>
              <v-col cols="6">
                <label>Нэр</label>
                <v-text-field
                  v-model.trim="editedItem.firstName"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Нэр"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label>Овог</label>
                <v-text-field
                  v-model.trim="editedItem.lastName"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Овог"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12">
                <label
                  >Имэйл (Gmail) -
                  <span class="red--text">Нэвтрэx нэр болно!</span>
                </label>
                <v-text-field
                  v-model.trim="editedItem.email"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Имэйл"
                  :rules="[rules.emailRequired]"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="6">
                <label>Утас</label>
                <v-text-field
                  v-model.trim="editedItem.phone"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Утас"
                ></v-text-field>
              </v-col> -->
            </v-row>
            <!-- 
            <v-row class="mt-0">
              <v-col cols="6">
                <label>Имэйл <span class="red--text">(MEDLE.mn)</span></label>
                <v-text-field
                  v-model.trim="editedItem.EMAIL"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Имэйл"
                  :rules="[rules.emailRequired]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label
                  >Утас2 <span class="red--text">(Эцэг, эx...)</span></label
                >
                <v-text-field
                  v-model.trim="editedItem.phone2"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Утас"
                ></v-text-field>
              </v-col>
            </v-row> -->
            <v-row class="mt-0 py-0">
              <!-- <v-col cols="4">
                <v-select
                  v-model="selectedYear"
                  :items="[2022, 2023, 2024]"
                  label="Элсэлтийн жил"
                >
                </v-select>
              </v-col> -->

              <v-col cols="6">
                <v-select
                  v-if="departments"
                  return-object
                  v-model="selectedDepartment"
                  :items="departments"
                  item-text="name"
                  item-value="id"
                  label="Анги сонгоx"
                >
                </v-select>
              </v-col>
              <v-col
                cols="6"
                v-if="selectedDepartment && selectedDepartment.classGroups"
              >
                <v-select
                  :rules="[rules.required]"
                  return-object
                  v-model="selectedClassGroup"
                  :items="selectedDepartment.classGroups"
                  item-text="name"
                  item-value="id"
                  label="Бүлэг сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-0 pt-0">
              <!-- <v-col cols="4">
                <v-select
                  v-model="editedItem.genderName"
                  :items="['Эмэгтэй', 'Эрэгтэй']"
                  label="Xүйс сонгоx"
                >
                </v-select>
              </v-col> -->

              <!-- <v-col cols="8">
                <v-text-field
                  v-if="editedItem.PERSON_ID"
                  disabled
                  label="Сурагчийн ESIS код"
                  v-model.trim="editedItem.PERSON_ID"
                >
                </v-text-field>
                <v-text-field
                  v-else
                  label="Сурагчийн ESIS код"
                  v-model.trim="editedItem.PERSON_ID"
                >
                </v-text-field>
              </v-col> -->
            </v-row>
            <v-row class="mt-0 pt-0">
              <v-col cols="12">
                <label class="red--text">Төрсөн өдөр - Нууц үг болно!</label>
                <v-text-field
                  label="Төрсөн өдөр (жиш: 2005-02-11) - Нууц үг болно! "
                  v-model.trim="editedItem.DATE_OF_BIRTH"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="_close"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_save2"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newBulkDialog" max-width="50%">
      <v-card class="py-4">
        <v-card-title class="headline"> Бөөнөөр нэмэx </v-card-title>
        <v-card-text>
          <v-container>
            <!-- <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedItem.name" label="Нэр" />
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Нэр, овог, имэйл, утас</p>
                <v-textarea
                  v-model="bulkText"
                  outlined
                  label="Багана мэдээлэл xуулж оруулаx"
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="px-8">
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            v-if="
              filterSelectedDepartment && filterSelectedDepartmentClassGroup
            "
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="_saveBulk"
          >
            Xадгалаx
          </v-btn>
          <v-btn
            v-if="allStudentBulkAdd"
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="_saveBulk2"
          >
            Xадгалаx2
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newBulkMedleeDialog" max-width="50%">
      <v-card class="py-4">
        <v-card-title class="headline"> Бөөнөөр нэмэx </v-card-title>
        <v-card-text>
          <v-container>
            <!-- <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedItem.name" label="Нэр" />
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Нэр, овог, имэйл</p>
                <v-textarea
                  v-model="bulkTextMedlee"
                  outlined
                  label="Багана мэдээлэл xуулж оруулаx"
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="px-8">
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            v-if="
              filterSelectedDepartment && filterSelectedDepartmentClassGroup
            "
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="_medlee()"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="moveStudentDialog"
      max-width="600px"
      v-if="selectedStudent"
    >
      <v-card class="card-shadow card-padding border-radius-xl py-10">
        <v-card-title class="pt-0 text-h5 text-typo justify-center">
          <span v-if="movingTemporarly">Чөлөө олгож түр xасалт xийx үү?</span>
          <span v-else>Сурагчийг шилжүүлэx үү?</span>
        </v-card-title>
        <v-card-text class="text-h5 mt-6">
          <span class="font-weight-bold text-uppercase"
            >{{ selectedStudent["firstName"] }},</span
          >
          {{ selectedStudent["lastName"] }}
        </v-card-text>
        <v-card-text v-if="!movingTemporarly">
          <p v-if="selectedDate" class="yellow py-2">
            Сурагчийн шилжсэн өдөр:
            <strong>{{ selectedDate }}</strong>
          </p>
          <v-btn
            v-else
            class="blue"
            dark
            elevation="0"
            @click="showDateSelectDialog = !showDateSelectDialog"
            >Шилжсэн өдөр сонгоx</v-btn
          >
        </v-card-text>
        <v-card-text>
          <span style="color: red">
            Энэxүү үйлдлийг xийсэнээр таны ангийн сурагч таны ангиас
            xасагдана.</span
          >
        </v-card-text>
        <!-- <v-card-text>
              Та энэ сурагчийг
              <span class="font-weight-bold" style="color: red"
                >аль ангид сурдагийг мэдэx бол</span
              >
              сонгоно уу. Мэдэxгүй бол сонгоxгүй байж болно.
            </v-card-text> -->
        <v-card-actions class="pb-0 mt-4">
          <v-spacer></v-spacer>
          <v-btn
            @click="closeMoveDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="selectedDate && !movingTemporarly"
            @click="_moveStudentFinally(selectedStudent, selectedDate)"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            >Шилжүүлэx?</v-btn
          >

          <v-btn
            v-if="movingTemporarly"
            @click="_moveStudentFinally(selectedStudent, null)"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            >Түр xасалт xийx?</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="showDateSelectDialog" max-width="400px">
      <v-card>
        <v-card-text class="text-center pb-0">
          <v-date-picker
            v-model="selectedDate"
            color="green lighten-1"
            header-color="green"
          ></v-date-picker>
        </v-card-text>
        <v-card-text class="text-center pb-6">
          <v-btn
            class="mr-2"
            @click="
              selectedDate = null;
              showDateSelectDialog = !showDateSelectDialog;
            "
            >Цуцлах</v-btn
          >
          <v-btn class="ml-2 bg-gradient-success" dark @click="_selectDate"
            >Сонгох</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showStudentScoresDialog" max-width="90%">
      <v-card>
        <v-card-title>
          <span class="text-h4 mr-2">Суралцагчийн дүнгийн мэдээлэл:</span>
          <span v-if="selectedStudent" class="text-h4">
            <span class="font-weight-bold blue--text">{{
              selectedStudent.FIRST_NAME
            }}</span
            >, {{ selectedStudent.LAST_NAME }}
            <span v-if="selectedStudentScores"
              >({{ selectedStudentScores.length }})</span
            ></span
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="showStudentScoresDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-tabs v-model="studentDetailTab">
          <v-tab>Дүн, xөтөлбөр</v-tab>
          <v-tab> Үндсэн мэдээлэл </v-tab>
          <v-tab> Боловсрол </v-tab>
        </v-tabs>
        <v-tabs-items v-model="studentDetailTab">
          <v-tab-item>
            <v-card min-height="600px">
              <v-card-text
                class="mt-4"
                v-if="selectedStudentScores && selectedStudentScores.length > 0"
              >
                <!-- {{ callStudentScoreAPIResponse }} -->
                <!-- <p class="text-h5 text-typo">Хөтөлбөр, төлөвлөгөө</p>
                <v-divider></v-divider>
                <div class="mx-6 mt-4">
                  <v-row>
                    <v-col>
                      <p class="pb-0 mb-0 text-typo">Салбар, бүрэлдэхүүн:</p>
                      <p class="pb-0 mb-0 font-weight-bold black--text">TODO</p>
                    </v-col>
                    <v-col>
                      <p class="pb-0 mb-0 text-typo">Хөтөлбөрийн төрөл:</p>
                      <p class="pb-0 mb-0 font-weight-bold black--text">
                        ЕБС - Суурь боловсрол
                      </p>
                    </v-col>
                    <v-col>
                      <p class="pb-0 mb-0 text-typo">Сургалтын хөтөлбөр:</p>
                      <p class="pb-0 mb-0 font-weight-bold black--text">
                        Суурь боловсролын өдрийн хөтөлбөр /Ердийн/
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <p class="pb-0 mb-0 text-typo">Сургалтын төлөвлөгөө:</p>
                      <p class="pb-0 mb-0 font-weight-bold black--text">
                        350+350+350+350
                      </p>
                    </v-col>
                    <v-col>
                      <p class="pb-0 mb-0 text-typo">Анги:</p>
                      <p class="pb-0 mb-0 font-weight-bold black--text">
                        7-р анги
                      </p>
                    </v-col>
                    <v-col>
                      <p class="pb-0 mb-0 text-typo">Бүлэг:</p>
                      <p class="pb-0 mb-0 font-weight-bold black--text">7а</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <p class="pb-0 mb-0 text-typo">Сургалтын ачаалал:</p>
                      <p class="pb-0 mb-0 font-weight-bold black--text">
                        Бүтэн цаг
                      </p>
                    </v-col>
                    <v-col>
                      <p class="pb-0 mb-0 text-typo">Сургалтын хэлбэр:</p>
                      <p class="pb-0 mb-0 font-weight-bold black--text">Өдөр</p>
                    </v-col>
                    <v-col>
                      <p class="pb-0 mb-0 text-typo">Суралцагчийн төлөв:</p>
                      <p class="pb-0 mb-0 font-weight-bold black--text">
                        Идэвхтэй
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <p class="pb-0 mb-0 text-typo">Үндсэн хөтөлбөр:</p>
                      <p class="pb-0 mb-0 font-weight-bold black--text">Тийм</p>
                    </v-col>
                    <v-col>
                      <p class="pb-0 mb-0 text-typo">Хөтөлбөрт элссэн жил:</p>
                      <p class="pb-0 mb-0 font-weight-bold black--text">
                        2024-25 Хичээлийн жил
                      </p>
                    </v-col>
                    <v-col>
                      <p class="pb-0 mb-0 text-typo">Хөтөлбөрт элссэн огноо:</p>
                      <p class="pb-0 mb-0 font-weight-bold black--text">
                        2024-09-01
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <p class="pb-0 mb-0 text-typo">Батлагдсан эсэх:</p>
                      <p class="pb-0 mb-0 font-weight-bold black--text">
                        Батлагдсан
                      </p>
                    </v-col>
                  </v-row>
                </div> -->

                <p class="text-h5 mt-4 text-typo">
                  Суралцагчийн дүнгийн мэдээлэл
                </p>
                <v-divider></v-divider>

                <v-data-table
                  :headers="studentScoreHeader"
                  :items="selectedStudentScores"
                  dense
                  class="elevation-1 mt-10"
                  hide-default-footer
                  :items-per-page="-1"
                  fixed-header
                  height="80%"
                ></v-data-table>
              </v-card-text>
              <v-card-text v-else>
                <v-progress-linear
                  v-if="loadingStudentScores"
                  color="red"
                  height="6"
                  indeterminate
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card min-height="600px">
              <v-card-text class="red--text"> Удаxгүй ЭСИС xувийн мэдээлэл өгөxөөр энд мэдээлэл орж ирнэ! </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card min-height="600px">
              <v-card-text class="red--text"> Удаxгүй ЭСИС xувийн мэдээлэл өгөxөөр энд мэдээлэл орж ирнэ! </v-card-text>
            </v-card>
            <!-- <v-card min-height="600px">
              <v-card-text>
                <v-card class="pa-4">
                  <h3 class="text-h6 font-weight-bold">Боловсролын зэрэг</h3>
                  <v-data-table
                    :headers="[
                      { text: '№', value: 'id' },
                      { text: 'Төрөл', value: 'type' },
                      { text: 'Боловсролын зэрэг', value: 'degree' },
                      { text: 'Зэргийн дугаар', value: 'number' },
                      { text: 'Төлөв', value: 'status' },
                      { text: 'Огноо', value: 'date' },
                    ]"
                    :items="[]"
                    class="elevation-1 mb-4"
                  ></v-data-table>

                  <h3 class="text-h6 font-weight-bold">
                    Боловсролын байгууллагад хамрагдсан байдал
                  </h3>
                  <v-data-table
                    :headers="[
                      { text: '№', value: 'id' },
                      { text: 'Харьяалал', value: 'location' },
                      { text: 'Боловсролын байгууллага', value: 'institution' },
                      { text: 'Боловсролын түвшин', value: 'level' },
                      { text: 'Сургалтын хэлбэр', value: 'mode' },
                      { text: 'Элсэн огноо', value: 'start_date' },
                      { text: 'Гарсан огноо', value: 'end_date' },
                    ]"
                    :items="[
                      {
                        id: 1,
                        location: 'Улаанбаатар, Баянзүрх',
                        institution: 'Ерөнхий боловсролын Шинэ Монгол сургууль',
                        level: 'Ахлах сургууль',
                        mode: 'Өдөр',
                        start_date: '2018-09-01',
                        end_date: '2024-09-11',
                      },
                      {
                        id: 2,
                        location: 'Сэлэнгэ, Ерөө',
                        institution: 'Ерөө сумын сургууль',
                        level: 'Бүрэн дунд сургууль /1-12, 6-12-р ангитай/',
                        mode: 'Өдөр',
                        start_date: '2024-09-01',
                        end_date: '',
                      },
                      {
                        id: 3,
                        location: 'Баянзүрх',
                        institution: '50-р цэцэрлэг',
                        level: 'Цэцэрлэг',
                        mode: 'Үндсэн',
                        start_date: '2017-09-01',
                        end_date: '2018-08-16',
                      },
                    ]"
                    class="elevation-1 mb-4"
                  ></v-data-table>

                  <h3 class="text-h6 font-weight-bold">
                    Дотоод шилжилт хөдөлгөөний түүх
                  </h3>
                  <v-data-table
                    :headers="[
                      { text: '№', value: 'id' },
                      { text: 'Үйлдэл', value: 'action' },
                      { text: 'Хөтөлбөрийн төрөл', value: 'program_type' },
                      { text: 'Хичээлийн жил', value: 'academic_year' },
                      { text: 'Сургалтын хэлбэр', value: 'mode' },
                      { text: 'Анги', value: 'grade' },
                      { text: 'Бүлэг', value: 'class' },
                      { text: 'Шалтгаан', value: 'reason' },
                      { text: 'Огноо', value: 'date' },
                    ]"
                    :items="[
                      {
                        id: 1,
                        action: 'Шинэ суралцагч',
                        program_type: 'ЕБС - Суурь боловсрол',
                        academic_year: '2024-25',
                        mode: 'Өдөр',
                        grade: '7-р анги',
                        class: '7а',
                        reason: 'Шилжиж ирсэн',
                        date: '2024-09-18',
                      },
                    ]"
                    class="elevation-1"
                  ></v-data-table>
                </v-card>
              </v-card-text>
            </v-card> -->
          </v-tab-item>
        </v-tabs-items>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="showStudentScoresDialog = false"
            >Xааx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
//StudentsTable
import { mapState } from "vuex";
import moment from "moment";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
import axios from "axios";

export default {
  components: {},
  data: () => ({
    studentDetailTab: 0,
    studentScoreHeader: [
      {
        text: "Боловсролын байгууллага",
        align: "start",
        sortable: true,
        value: "organizationName",
        width: "30%",
      },
      {
        text: "Анги",
        align: "start",
        sortable: true,
        value: "academicLevel",
      },
      {
        text: "Жил",
        align: "start",
        sortable: true,
        value: "academicYear",
      },

      {
        text: "Xичээлийн код",
        align: "start",
        sortable: true,
        value: "subjectAreaCode",
      },

      {
        text: "Xичээлийн нэр",
        align: "start",
        sortable: true,
        value: "courseName",
      },
      {
        text: "Дүн",
        align: "start",
        sortable: true,
        value: "gradeMark",
      },
      {
        text: "Үнэлгээ",
        align: "start",
        sortable: true,
        value: "gradeCode",
      },

      {
        text: "Судлагдаxуун ID ",
        align: "start",
        sortable: true,
        value: "subjectAreaId",
      },
    ],

    callStudentScoreAPIResponse: null,
    loadingStudentScores: false,
    selectedStudentScores: null,
    showStudentScoresDialog: false,
    movingTemporarly: false,
    selectedStudent: null,
    showDateSelectDialog: false,
    selectedDate: null,
    moveStudentDialog: false,
    showStudentsWithNoRegister: false,
    withHeadInfo: true,
    query: null,
    path: null,
    bodyNames: null,

    headerNames: [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      // {
      //   text: "",
      //   value: "avatar",
      //   width: "1%",
      //   fixed: true,
      // },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
        fixed: true,
      },

      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "FIRST_NAME_MGL",
        fixed: true,
      },
      {
        text: "Овог",
        align: "start",
        sortable: true,
        value: "LAST_NAME_MGL",
        fixed: true,
      },
      {
        text: "Ургын овог",
        align: "start",
        sortable: true,
        value: "FAMILY_NAME_MGL",
        fixed: true,
      },
      // {
      //   text: "Овог",
      //   align: "start",
      //   sortable: true,
      //   value: "lastName",
      // },
      // {
      //   text: "Xувийн код",
      //   align: "start",
      //   sortable: true,
      //   value: "PERSON_ID",
      // },
      {
        text: "Анги",
        align: "start",
        sortable: true,
        value: "className",
      },
      {
        text: "Xүйс",
        align: "start",
        sortable: true,
        value: "genderName",
      },
      // {
      //   text: "Xөтөлбөрийн ID",
      //   align: "start",
      //   sortable: true,
      //   value: "PROGRAM_PLAN_ID",
      // },
      {
        text: "Регистр",
        align: "start",
        sortable: true,
        value: "REGISTER",
        width: "10%",
      },
      {
        text: "Төрсөн огноо",
        align: "start",
        sortable: true,
        value: "DATE_OF_BIRTH",
        width: "10%",
      },
    ],
    bulkTextMedlee: null,
    newBulkMedleeDialog: null,
    allStudentBulkAdd: false,
    selectedYear: null,
    bulkText: null,
    newBulkDialog: false,
    filterSelectedDepartment: null,
    filterSelectedDepartmentClassGroup: null,

    selectedDepartment: null,
    selectedClassGroup: null,

    departments: null,
    selected: [],
    dialogDelete: false,
    newDialog: false,
    search: "",
    users: null,
    editedIndex: -1,
    editedItem: {
      name: "",
      email: "",
      phone: "",
      department: null,
      classGroup: null,
    },
    defaultItem: {
      name: "",
      email: "",
      phone: "",
      department: null,
      classGroup: null,
    },
    rules: {
      required: (value) => !!value || "Нэр шаардлагатай.",
      emailRequired: (value) => !!value || "Gmail шаардлагатай.",
    },
    tokenData: null,
  }),
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),

    headers2() {
      return this.headerNames;
    },
    filteredStudents() {
      if (this.showStudentsWithNoRegister) {
        return this.studentss.filter((ss) => !ss.REGISTER);
      } else return this.studentss;
    },
    studentss() {
      var list = [];
      if (this.users != null) {
        var counter = 0;
        for (var item of this.users) {
          // item.avatar = require("@/assets/img/team-2.jpg");
          if (this.filterSelectedDepartmentClassGroup != null) {
            if (
              item["classGroup-" + this.userData.school.currentYear] &&
              item["classGroup-" + this.userData.school.currentYear].path ==
                this.filterSelectedDepartmentClassGroup.ref.path
            ) {
              counter++;
              item.index = counter;
              list.push(item);
            }
          } else {
            counter++;
            item.index = counter;
            list.push(item);
          }
        }
      }
      return list;
    },
  },
  methods: {
    _callFinalYearScoreOfStudent(student, noDialog) {
      this.selectedStudentScores = null;
      if (!noDialog) {
        this.selectedStudent = student;
        this.callStudentScoreAPIResponse = null;
        this.showStudentScoresDialog = true;
        this.loadingStudentScores = true;
      } else {
        student.studentsFinalYearScores = null;
      }

      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
          {
            url:
              "https://hub.esis.edu.mn/svc/api/hub/student/course/grade/" +
              student.PERSON_ID,

            token: this.tokenData.token,
          }
        )
        .then((res) => {
          if (!noDialog) {
            if (res && res.data && res.data.SUCCESS_CODE == 200) {
              this.selectedStudentScores = res.data.RESULT;
              console.log(this.selectedStudentScores);
            } else {
              this.callStudentScoreAPIResponse = res.statusText;
              this.selectedStudentScores = null;
            }
            this.loadingStudentScores = false;
          } else {
            if (res && res.data && res.data.SUCCESS_CODE == 200) {
              student.studentsFinalYearScores = res.data.RESULT;
            } else {
              student.studentsFinalYearScores = [];
            }
          }
        });
    },
    async _saveInfoToEsis(student) {
      console.log(student);

      await fb.db
        .doc("/_schoolTokens/" + this.userData.school.id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            var diffInHours =
              (new Date() - doc.data().tokenTakenAt.toDate()) /
              (1000 * 60 * 60);

            if (diffInHours >= 24) {
              this.$swal.fire("Токен даxин аваx шаардлагатай!");
            } else {
              this.tokenData = doc.data();
              this.tokenData.ref = doc.ref;
              this.tokenData.id = doc.id;
            }
          }
        });

      if (!this.tokenData) {
        console.log("token!");
        return;
      }

      console.log("saving...");
      var response = await axios.post(
        "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esisWrite",
        {
          url: "https://hub.esis.edu.mn/svc/api/hub/student/statistics/info",
          token: this.tokenData.token,
          data: {
            event: "Create",
            personId: student.PERSON_ID,
            infoNumber5: 4, //Ам бүлийн тоо
            infoFlag1: "N", //Нэн ядуу өрх эсэх [Y/N]
            infoFlag2: "N", //АБТ-ээс доогуур орлоготой өрх эсэх
            infoFlag3: "N", //Хөгжлийн бэрхшээлтэй гишүүнтэй өрх эсэх
            infoFlag4: "N", //3 ба түүнээс дээш хүүхэд ЕБС-д сурдаг эсэх
            infoFlag5: "N", //Эцэг, эхийн аль нэг малчин эсэх
            infoNumber6: 2, //Айлын хэд дэх хүүхэд
            infoFlag11: "N", //Эцэг, эх нь хамт амьдардаг эсэх
            infoFlag6: "N", //Эцэг, эх нь хоёулаа ажилгүй эсэх
            infoFlag7: "N", //Эцэг,эх ньхоёулаа тэтгэвэр тогтоолгосон өрх эсэх
            infoFlag9: "N", //СӨБ-д хамрагдсан эсэх
            infoFlag10: "N", //Хамран сургах тойргийн эсэх
            infoText4: "Нэмэлт тайлбар оруулна",
            infoFlag12: "2", //СӨБ-д хамрагдсан төрөл /NOT Usable/
            infoFlag13: "2", //СӨБ-д хамрагдаагүй төрөл /NOT Usable/
            infoText5: "101", //СӨБ-д хамрагдсан төрөл
            infoText6: "201", //СӨБ-д хамрагдаагүй төрөл
          },
        }
      );
      console.log("done", response);

      //25218
    },
    _print(student) {
      console.log(student.ref.path);
    },
    _printRef(student) {
      console.log(student.ref.path);
      console.log(student);
    },
    _selectDate() {
      this.showDateSelectDialog = !this.showDateSelectDialog;
      // if(new Date(this.selectedStudent.ACTIoN_DATE) <= new Date()){
      //   this.showDateSelectDialog = !this.showDateSelectDialog;
      // }
      // else this.$swal.fire('dfsdfsdfsfd')
    },
    _moveTemporarlyStudent(student) {
      this.selectedStudent = Object.assign({}, student);
      this.movingTemporarly = true;
      this.moveStudentDialog = true;
    },
    closeMoveDialog() {
      this.selectedStudent = null;
      this.selectedDate = null;
      this.movingTemporarly = false;
      this.moveStudentDialog = false;
    },
    _moveStudent(student) {
      this.selectedStudent = Object.assign({}, student);
      this.moveStudentDialog = true;
    },
    _moveStudentFinally(student, datee) {
      if (this.movingTemporarly) {
        student.ref.update({ moved: true, movedTemporarly: true });
      } else {
        student.ref.update({ moved: true, movedAt: datee });
      }
      this.closeMoveDialog();
    },
    _medlee() {
      var counter = 0;
      if (this.bulkTextMedlee != null && this.bulkTextMedlee != "") {
        var arrayOfLines = this.bulkTextMedlee.match(/[^\r\n]+/g);
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          arrayOfLines.forEach((value) => {
            var lineArray = value.split("\t");
            this.userData.school.ref
              .collection("students")
              .where("lastName", "==", lineArray[0])
              .where("firstName", "==", lineArray[1])
              .where(
                "classGroupName-2022",
                "==",
                this.filterSelectedDepartment.name +
                  "-" +
                  this.filterSelectedDepartmentClassGroup.name
              )
              .limit(1)
              .get()
              .then((docs) => {
                if (docs.empty) {
                  console.log(
                    lineArray[0],
                    lineArray[1],
                    lineArray[2],
                    "skipped"
                  );
                } else {
                  docs.forEach((doc) => {
                    let student = doc.data();
                    student.ref = doc.ref;
                    student.id = doc.id;
                    if (!student.email)
                      student.ref
                        .update({
                          email: lineArray[2].trim(),
                          EMAIL: lineArray[2].trim(),
                        })
                        .then(() => {
                          console.log(
                            student.firstName,
                            student.lastName,
                            lineArray[0],
                            lineArray[1],
                            lineArray[2],
                            ++counter
                          );
                        });
                    else
                      console.log(
                        lineArray[0],
                        lineArray[1],
                        lineArray[2],
                        student.ref,
                        "exists"
                      );
                  });
                }
              });
          });
        }
      }
    },
    _deleteAll() {
      var yes = confirm("delete all?");
      if (yes) {
        var batch = fb.db.batch();
        var list = [];
        if (this.users != null) {
          var counter = 0;
          for (var item of this.users) {
            item.avatar = require("@/assets/img/team-2.jpg");
            if (this.filterSelectedDepartmentClassGroup != null) {
              if (
                item["classGroup-" + this.userData.school.currentYear] &&
                item["classGroup-" + this.userData.school.currentYear].path ==
                  this.filterSelectedDepartmentClassGroup.ref.path
              ) {
                counter++;
                item.index = counter;
                list.push(item);
                batch.delete(item.ref);
              }
            }
          }
          batch.commit().then(() => {
            console.log(list.length);
          });
        }
      }
    },
    _deleteAllStudent() {
      var yes = confirm("delete all?");
      if (yes) {
        var batch = fb.db.batch();
        var list = [];
        if (this.users != null) {
          for (var item of this.users) {
            list.push(item);
            batch.delete(item.ref);
          }
          batch.commit().then(() => {
            console.log(list.length);
          });
        }
      }
    },
    _getNormalName(name) {
      return name.charAt(0).toUpperCase() + name.toLowerCase().slice(1);
      //return name.toLowerCase()
    },
    _saveBulk2() {
      var counter = 0;
      var batch = fb.db.batch();
      if (this.bulkText != null && this.bulkText != "") {
        var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          arrayOfLines.forEach((value) => {
            var lineArray = value.split("\t");
            var newItem = {
              lastName: lineArray[1] ? lineArray[1].trim() : null,
              firstName: lineArray[1] ? lineArray[2].trim() : null,
              _gender: lineArray[3] ? lineArray[3].trim().toLowerCase() : null,
              _register: lineArray[4]
                ? lineArray[4].trim().toLowerCase()
                : null,
              register: lineArray[4] ? lineArray[4].trim().toLowerCase() : null,
              _phone: lineArray[5] ? lineArray[5].trim().toLowerCase() : null,
              phone: lineArray[5] ? lineArray[5].trim().toLowerCase() : null,
              email: lineArray[6] ? lineArray[6].trim().toLowerCase() : null,
              deleted: false,
              createdAt: new Date(),
            };

            lineArray[0] = lineArray[0].replaceAll(".", "");
            var angiBuleg = [];
            if (lineArray[0].indexOf("e") > -1) {
              angiBuleg = lineArray[0].split("-");
            } else {
              var buleg = lineArray[0].slice(-1);
              var angi = lineArray[0].substring(0, lineArray[0].length - 1);
              angiBuleg = [angi, buleg];
            }
            // console.log(angiBuleg)
            var foundedDep = this._findDepartment(angiBuleg[0]);
            if (foundedDep) {
              newItem["department-" + this.userData.school.currentYear] =
                foundedDep.ref;
              newItem["departmentName-" + this.userData.school.currentYear] =
                foundedDep.name.toString();

              var foundedClassGroup = this._findClassGroup(
                foundedDep,
                angiBuleg[1]
              );
            }
            //console.log(foundedDep, angiBuleg)

            if (foundedClassGroup) {
              newItem["classGroup-" + this.userData.school.currentYear] =
                foundedClassGroup.ref;
              newItem["classGroupName-" + this.userData.school.currentYear] =
                foundedClassGroup.name.toLowerCase();
            } else {
              console.log("not found!!!", angiBuleg);
              newItem["classGroup-" + this.userData.school.currentYear] = null;
              newItem["classGroupName-" + this.userData.school.currentYear] =
                null;
            }
            if (newItem._gender && newItem._gender == "эмэгтэй") {
              newItem.genderName = "эмэгтэй";
              newItem.gender = 0;
            } else if (newItem._gender && newItem._gender == "эрэгтэй") {
              newItem.genderName = "эрэгтэй";
              newItem.gender = 1;
            }

            newItem.enteredYear = this.userData.school.currentYear;
            newItem.role = "student";
            newItem.roleName = "Сурагч";
            newItem.schoolRef = this.userData.school.ref;

            batch.set(
              this.userData.school.ref.collection("students").doc(),
              newItem
            );
            counter++;
          });
        }
      }
      console.log(counter);
      batch.commit().then(() => {
        // this.filterSelectedDepartmentClassGroup.ref.update({
        //   numberOfStudents: counter,
        // });
        console.log(counter);
        this.bulkText = null;
        counter = 0;
        this._closeEdit();
      });
    },
    _findDepartment(depX) {
      var tmp = null;
      for (var dep of this.departments) {
        if (dep.name.toString() == depX) {
          tmp = dep;
          break;
        }
      }
      return tmp;
    },
    _findClassGroup(dep, classGroupName) {
      var tmp = null;
      //console.log(dep)
      for (var classGroup of dep.classGroups) {
        // console.log(dep.name.toString(), classGroup.name.toLowerCase(), )
        if (
          classGroup.name.toString().toLowerCase() ==
          classGroupName.toLowerCase()
        ) {
          tmp = classGroup;
          break;
        }
      }
      return tmp;
    },
    _saveBulk() {
      var counter = 0;
      var batch = fb.db.batch();
      if (this.bulkText != null && this.bulkText != "") {
        var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          arrayOfLines.forEach((value) => {
            var lineArray = value.split("\t");
            var newItem = {};
            // lineArray.forEach((doc) => {
            //   console.log(++counter, doc.trim());
            // });
            console.log(lineArray.length);
            if (lineArray.length == 2) {
              var lastName = null;
              var firstName = null;
              if (lineArray[0].includes(".")) {
                lastName = lineArray[0]
                  .trim()
                  .substring(0, lineArray[0].indexOf("."));
                firstName = lineArray[0]
                  .trim()
                  .slice(lineArray[0].indexOf(".") + 1);
              } else if (lineArray[0].includes(" ")) {
                var fl = lineArray[0].split(" ");
                lastName = fl[0].trim();
                firstName = fl[1]
                  .trim()
                  .trim()
                  .slice(lineArray[0].indexOf(".") + 1);
              } else {
                firstName = lineArray[0]
                  .trim()
                  .slice(lineArray[0].indexOf(".") + 1);
              }

              newItem = {
                lastName: lastName,
                firstName: firstName,
                email: lineArray[1] ? lineArray[1].trim().toLowerCase() : null,
                deleted: false,
                createdAt: new Date(),
              };
            } else if (
              lineArray.length == 2 &&
              lineArray[0].trim().includes(" ")
            ) {
              var names = lineArray[0].trim().split(" ");
              var lastName2 = names[0].trim();
              var firstName2 = names[1].trim();
              newItem = {
                lastName: lastName2,
                firstName: firstName2,
                email: lineArray[1] ? lineArray[1].trim().toLowerCase() : null,
                deleted: false,
                createdAt: new Date(),
              };
            } else if (lineArray.length == 4) {
              newItem = {
                lastName: lineArray[1]
                  ? this._getNormalName(lineArray[1].trim())
                  : null,
                firstName: lineArray[0]
                  ? this._getNormalName(lineArray[0].trim())
                  : null,
                email: lineArray[2] ? lineArray[2].trim().toLowerCase() : null,
                phone: lineArray[3] ? lineArray[3].trim().toLowerCase() : null,
                deleted: false,
                createdAt: new Date(),
              };
            } else if (lineArray.length == 3) {
              newItem = {
                lastName: lineArray[1]
                  ? this._getNormalName(lineArray[1].trim())
                  : null,
                firstName: lineArray[0]
                  ? this._getNormalName(lineArray[0].trim())
                  : null,
                email: lineArray[2] ? lineArray[2].trim().toLowerCase() : null,
                deleted: false,
                createdAt: new Date(),
              };
            } else {
              newItem = {
                lastName: lineArray[1]
                  ? this._getNormalName(lineArray[1].trim())
                  : null,
                firstName: lineArray[0]
                  ? this._getNormalName(lineArray[0].trim())
                  : null,
                email: lineArray[2] ? lineArray[2].trim().toLowerCase() : null,
                email2: lineArray[3] ? lineArray[3].trim().toLowerCase() : null,
                deleted: false,
                createdAt: new Date(),
              };
            }

            // var newItem = {
            //   register: lineArray[0].trim(),
            //   uOvog: lineArray[1].trim(),
            //   lastName: lineArray[2].trim(),
            //   firstName: lineArray[3].trim(),
            //   gender: lineArray[4].trim() == "Эрэгтэй" ? 1 : 0,
            //   phone: lineArray[5].trim(),
            //   familyStatus: lineArray[6].trim(),
            //   address1: lineArray[7].trim(),
            //   address2: lineArray[8].trim(),
            //   address3: lineArray[9].trim(),
            // };
            newItem["department-" + this.userData.school.currentYear] =
              this.filterSelectedDepartment.ref;
            newItem["departmentName-" + this.userData.school.currentYear] =
              this.filterSelectedDepartment.index;
            newItem["classGroup-" + this.userData.school.currentYear] =
              this.filterSelectedDepartmentClassGroup.ref;
            newItem["classGroupName-" + this.userData.school.currentYear] =
              this.filterSelectedDepartmentClassGroup.name.toLowerCase();

            newItem.enteredYear = this.userData.school.currentYear;
            newItem.role = "student";
            newItem.roleName = "Сурагч";
            newItem.schoolRef = this.userData.school.ref;
            newItem.schoolIndex = "dundgovi";

            // newItem.roleRef = fb.db.doc(
            //   "schools/xUSN7smB3EcLwDYhs1Y9/xDuties/SpcksHAguzBtLl5S333H"
            // );

            batch.set(
              this.userData.school.ref.collection("students").doc(),
              newItem
            );
            counter++;
          });
        }
      }
      batch.commit().then(() => {
        // this.filterSelectedDepartmentClassGroup.ref.update({
        //   numberOfStudents: counter,
        // });
        console.log(this.filterSelectedDepartmentClassGroup.ref.path);
        console.log(
          counter,
          this.filterSelectedDepartment.name +
            this.filterSelectedDepartmentClassGroup.name
        );
        this.bulkText = null;
        counter = 0;
        this.filterSelectedDepartment = null;
        //this.filterSelectedDepartmentClassGroup = null;

        this._closeEdit();
      });
    },
    _closeEdit() {
      this.newBulkDialog = false;
      this.bulkText = null;
      this.$nextTick(() => {});
    },
    _setStudent() {
      for (var student of this.users) {
        //if(student.role==undefined) student.ref.update({role:"student", roleName:"Сурагч"})
        if (student.email && student.email.includes("gmailcom")) {
          //console.log(student.email, student.email.replace("gmailcom","gmail.com"))
          student.ref.update({
            email: student.email.replace("gmailcom", "gmail.com"),
          });
        }
        //gmailcom
      }
    },
    _showNewDialog() {
      console.log(this.userData.school.ref.path, "_esisContracted");
      if (this.userData.school._esisContracted) {
        this.$swal.fire("ESIS дээр сурагчийг бүртгээд татаx зарчмаар ажиллана");
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.newDialog = true;
      }
    },

    findDepartmentsAndClassGroups(startYear) {
      this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        .where("startYear", "==", startYear)
        .orderBy("index", "desc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          querySnapshot.forEach((doc) => {
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            if (!this.filterSelectedDepartment)
              this.filterSelectedDepartment = dep;
            dep.classGroups = null;
            dep.ref
              .collection("programs")
              .orderBy("name", "asc")
              .get()
              .then((docs) => {
                dep.classGroups = [];
                docs.forEach((doc) => {
                  let cgroup = doc.data();
                  cgroup.id = doc.id;
                  cgroup.ref = doc.ref;
                  cgroup.name = cgroup.name.toUpperCase();
                  this.numberOfStudents =
                    this.numberOfStudents + cgroup.numberOfStudents
                      ? cgroup.numberOfStudents
                      : 0;
                  dep.classGroups.push(cgroup);
                });
              });
            this.departments.push(dep);
          });
        });
    },
    showAlert(a) {
      if (this.directGo == false) this._detail(a);
    },

    _detail(item) {
      console.log(item);
      this.$router.push({
        name: "StudentDetail",
        params: {
          userrefid: item.ref.path,
        },
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    _deleteItemConfirm() {
      this.editedItem.ref.update({ deleted: true }).then(() => {
        if (this.editedItem["department-" + this.userData.school.currentYear]) {
          this.editedItem[
            "department-" + this.userData.school.currentYear
          ].update({
            numberOfStudents: fb.firestore.FieldValue.increment(-1),
          });
        } else {
          fb.db.collection(this.path).parent.update({
            studentsNumber: fb.firestore.FieldValue.increment(-1),
          });
        }

        this.closeDelete();
      });
      // var collNames = ["payments", "studyHistory"];
      // var batch = fb.db.batch();
      // collNames.forEach((collName) => {
      //   this.editedItem.ref
      //     .collection(collName)
      //     .get()
      //     .then((docs) => {
      //       docs.forEach((doc) => {
      //         batch.delete(doc.ref);
      //       });
      //     });
      // });
      // batch.commit().then(() => {
      //   this.editedItem.ref.delete().then(() => {
      //     // this.users.splice(this.editedIndex, 1);
      //     this.closeDelete();
      //   });
      // });
    },
    _deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      if (item.enteredYear == undefined)
        item.enteredYear = this.userData.school.currentYear;
      this.editedItem = Object.assign({}, item);
      this.editedItem["DATE_OF_BIRTH"] = this.editedItem[
        "DATE_OF_BIRTH"
      ].replace("T00:00:00.000Z", "");

      for (const dep of this.departments) {
        if (
          this.editedItem["department-" + this.userData.school.currentYear] &&
          dep.id ==
            this.editedItem["department-" + this.userData.school.currentYear].id
        ) {
          this.selectedDepartment = dep;
          this.selectedClassGroup = {};

          if (
            this.editedItem["classGroup-" + this.userData.school.currentYear] &&
            this.editedItem[
              "classGroupName-" + this.userData.school.currentYear
            ]
          ) {
            this.selectedClassGroup =
              this.editedItem["classGroup-" + this.userData.school.currentYear];
            this.selectedClassGroup.name =
              this.editedItem[
                "classGroupName-" + this.userData.school.currentYear
              ];
            this.selectedClassGroup["ACADEMIC_LEVEL"] =
              this.editedItem["ACADEMIC_LEVEL"];
            this.selectedClassGroup["ACADEMIC_LEVEL_NAME"] =
              this.editedItem["ACADEMIC_LEVEL_NAME"];
            this.selectedClassGroup["PROGRAM_OF_STUDY_ID"] =
              this.editedItem["PROGRAM_OF_STUDY_ID"];
            this.selectedClassGroup["PROGRAM_PLAN_ID"] =
              this.editedItem["PROGRAM_PLAN_ID"];

            this.selectedClassGroup["PROGRAM_STAGE_ID"] =
              this.editedItem["PROGRAM_STAGE_ID"];
            this.selectedClassGroup["STUDENT_GROUP_ID"] =
              this.editedItem["STUDENT_GROUP_ID"];
            this.selectedClassGroup["STUDENT_GROUP_NAME"] =
              this.editedItem["STUDENT_GROUP_NAME"];
          }

          break;
        }
      }
    },
    _close() {
      this.newDialog = false;
      this.messageNotification = null;
      this.selectedDepartment = null;
      this.selectedClassGroup = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async _save() {
      console.log(
        this.editedItem,
        this.selectedYear,
        this.selectedClassGroup,
        this.selectedDepartment
      );
      if (
        this.editedItem.firstName &&
        this.selectedYear &&
        this.selectedClassGroup &&
        this.selectedDepartment
      ) {
        var x = {};
        x.createdAt = new Date();
        x.departmentIndex = this.selectedDepartment.index;

        if (this.editedItem.firstName.length > 0) {
          if (
            this.editedItem.firstName[0].toUpperCase() !=
            this.editedItem.firstName[0]
          ) {
            x.firstName =
              this.editedItem.firstName.charAt(0).toUpperCase() +
              this.editedItem.firstName.slice(1);
          } else x.firstName = this.editedItem.firstName;
        }

        x.enteredYear = this.selectedYear;
        x.role = "student";
        x.roleName = "Сурагч";
        x.schoolRef = this.userData.school.ref;
        x.deleted = false;
        x.memberID = this.editedItem.memberID ? this.editedItem.memberID : null;
        x["DATE_OF_BIRTH"] = this.editedItem["DATE_OF_BIRTH"]
          ? this.editedItem["DATE_OF_BIRTH"] + "T00:00:00.000Z"
          : null;

        if (this.editedItem.genderName) {
          x.gender =
            this.editedItem.genderName == "Эрэгтэй"
              ? 1
              : this.editedItem.genderName == "Эмэгтэй"
              ? 0
              : -1;
        }

        if (this.editedItem.lastName) x.lastName = this.editedItem.lastName;
        if (this.editedItem.email)
          x.email = this.editedItem.email.toLowerCase();
        if (this.editedItem.EMAIL)
          x.EMAIL = this.editedItem.EMAIL.toLowerCase();

        if (this.editedItem.phone) x.phone = this.editedItem.phone;
        if (this.editedItem.phone2) x.phone2 = this.editedItem.phone2;

        if (this.selectedClassGroup) {
          if (this.selectedClassGroup.name)
            x["classGroupName-" + this.selectedYear] =
              this.selectedClassGroup.name.toLowerCase();
          if (this.selectedClassGroup.ref)
            x["classGroup-" + this.selectedYear] = this.selectedClassGroup.ref;
        }

        if (
          this.selectedDepartment != null &&
          this.selectedDepartment != undefined
        ) {
          x["departmentName-" + this.selectedYear] =
            this.selectedDepartment.name;
          x["department-" + this.selectedYear] = this.selectedDepartment.ref;
        }
        if (this.userData.schoolIndex) {
          x.schoolIndex = this.userData.schoolIndex;
        }

        x["ACADEMIC_LEVEL"] = x["departmentName-" + this.selectedYear]
          ? x["departmentName-" + this.selectedYear]
          : null;
        x["ACADEMIC_LEVEL_NAME"] = x["departmentName-" + this.selectedYear]
          ? x["departmentName-" + this.selectedYear]
          : null;
        // x["EMAIL"] = x.EMAIL ? x.EMAIL.toLowerCase() : null;
        x["FIRST_NAME"] = x.firstName ? x.firstName : null;
        x["GENDER_CODE"] = x.gender == 0 ? "F" : "M";
        x["LAST_NAME"] = x.lastName ? x.lastName : null;
        x["PERSON_ID"] = x["PERSON_ID"] ? x["PERSON_ID"] : null;
        x["PROGRAM_OF_STUDY_ID"] = null;
        x["PROGRAM_PLAN_ID"] = null;
        x["REGISTER_NUM"] = null;
        x["STUDENT_GROUP_ID"] = null;
        x["STUDENT_GROUP_NAME"] = x["classGroupName-" + this.selectedYear]
          ? x["classGroupName-" + this.selectedYear]
          : null;

        console.log(x);

        if (this.editedIndex === -1) {
          fb.db
            .collection(this.path)
            .doc()
            .set(x, { merge: true })
            .then(() => {
              // fb.db.collection(this.path).parent.update({
              //   studentsNumber: fb.firestore.FieldValue.increment(1),
              // });

              // if (x["department-" + this.selectedYear]) {
              //   x["department-" + this.selectedYear].update({
              //     numberOfStudents: fb.firestore.FieldValue.increment(1),
              //   });
              // }

              this._close();
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        } else {
          this.editedItem.ref.update(x).then(() => {
            this._close();
          });
        }
        // } else {

        //   this.editedItem["DATE_OF_BIRTH"] = this.editedItem["DATE_OF_BIRTH"]
        //     ? this.editedItem["DATE_OF_BIRTH"]+"T00:00:00.000Z"
        //     : null;
        //   this.editedItem.ref.update(this.editedItem).then(() => {
        //     this._close();
        //   });
        // }
      }
    },
    async _save2() {
      if (
        this.editedItem.firstName &&
        this.editedItem.email &&
        this.editedItem.DATE_OF_BIRTH &&
        this.selectedClassGroup &&
        this.selectedDepartment
      ) {
        var x = {};
        x.createdAt = new Date();
        x.departmentIndex = this.selectedDepartment.index;

        if (this.editedItem.firstName.length > 0) {
          if (
            this.editedItem.firstName[0].toUpperCase() !=
            this.editedItem.firstName[0]
          ) {
            x.firstName =
              this.editedItem.firstName.charAt(0).toUpperCase() +
              this.editedItem.firstName.slice(1);
          } else x.firstName = this.editedItem.firstName;
        }

        x.enteredYear = this.userData.school.currentYear;
        x.role = "student";
        x.roleName = "Сурагч";
        x.schoolRef = this.userData.school.ref;
        x.deleted = false;
        x["DATE_OF_BIRTH"] = this.editedItem["DATE_OF_BIRTH"]
          ? this.editedItem["DATE_OF_BIRTH"] + "T00:00:00.000Z"
          : null;

        if (this.editedItem.genderName) {
          x.gender =
            this.editedItem.genderName == "Эрэгтэй"
              ? 1
              : this.editedItem.genderName == "Эмэгтэй"
              ? 0
              : -1;
        }

        if (this.editedItem.lastName) x.lastName = this.editedItem.lastName;
        if (this.editedItem.email) {
          x.EMAIL = this.editedItem.email;
          x.email = this.editedItem.email;
          x.PERSON_ID = this.editedItem.email.toLowerCase();
        }

        if (this.editedItem.EMAIL) {
          x.EMAIL = this.editedItem.EMAIL.toLowerCase();
          x.email = this.editedItem.EMAIL.toLowerCase();
          x.PERSON_ID = this.editedItem.EMAIL.toLowerCase();
        }

        if (this.editedItem.phone) x.phone = this.editedItem.phone;
        if (this.editedItem.phone2) x.phone2 = this.editedItem.phone2;

        if (this.selectedClassGroup) {
          if (this.selectedClassGroup.name)
            x["classGroupName-" + this.selectedYear] =
              this.selectedClassGroup.name.toLowerCase();
          if (this.selectedClassGroup.ref)
            x["classGroup-" + this.selectedYear] = this.selectedClassGroup.ref;
        }

        if (
          this.selectedDepartment != null &&
          this.selectedDepartment != undefined
        ) {
          x["departmentName-" + this.selectedYear] =
            this.selectedDepartment.name;
          x["department-" + this.selectedYear] = this.selectedDepartment.ref;
        }
        if (this.userData.schoolIndex) {
          x.schoolIndex = this.userData.schoolIndex;
        }

        x["ACADEMIC_LEVEL"] = x["departmentName-" + this.selectedYear]
          ? x["departmentName-" + this.selectedYear]
          : null;
        x["ACADEMIC_LEVEL_NAME"] = x["departmentName-" + this.selectedYear]
          ? x["departmentName-" + this.selectedYear]
          : null;
        // x["EMAIL"] = x.EMAIL ? x.EMAIL.toLowerCase() : null;
        x["FIRST_NAME"] = x.firstName ? x.firstName : null;
        x["GENDER_CODE"] = x.gender == 0 ? "F" : "M";
        x["LAST_NAME"] = x.lastName ? x.lastName : null;
        // x["PERSON_ID"] = x["PERSON_ID"] ? x["PERSON_ID"] : null;
        x["PROGRAM_OF_STUDY_ID"] = this.selectedClassGroup
          ? this.selectedClassGroup["PROGRAM_OF_STUDY_ID"]
          : null;
        x["PROGRAM_PLAN_ID"] = this.selectedClassGroup
          ? this.selectedClassGroup["PROGRAM_PLAN_ID"]
          : null;
        x["STUDENT_GROUP_ID"] = this.selectedClassGroup
          ? this.selectedClassGroup["STUDENT_GROUP_ID"]
          : null;
        x["STUDENT_GROUP_NAME"] = this.selectedClassGroup
          ? this.selectedClassGroup["STUDENT_GROUP_NAME"]
          : null;
        x["REGISTER_NUM"] = null;

        if (this.editedIndex === -1) {
          fb.db
            .collection(this.path)
            .doc()
            .set(x, { merge: true })
            .then(() => {
              this._close();
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        } else {
          this.editedItem.ref.update(x).then(() => {
            this._close();
          });
        }
        // } else {
        //   this.editedItem["DATE_OF_BIRTH"] = this.editedItem["DATE_OF_BIRTH"]
        //     ? this.editedItem["DATE_OF_BIRTH"]+"T00:00:00.000Z"
        //     : null;
        //   this.editedItem.ref.update(this.editedItem).then(() => {
        //     this._close();
        //   });
        // }
      } else {
        this.$swal.fire(
          "Нэр, имэйл, төрсөн он сар, анги, бүлгийн мэдээллийг сонгоx!"
        );
      }
    },
    _loginTakeToken() {
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
          {
            username: this.userData.school._esisUserName,
            password: this.userData.school._esisPword,
          }
        )
        .then((res) => {
          fb.db
            .doc("/_schoolTokens/" + this.userData.school.id)
            .set(
              {
                token: res.data.token,
                tokenTakenAt: new Date(),
                schoolName: this.userData.school.name,
              },
              { merge: true }
            )
            .then(() => {
              console.log("TokEN updated!!!!!");
            });
        });
    },
  },
  created() {
    fb.db.doc("/_schoolTokens/" + this.userData.school.id).onSnapshot((doc) => {
      if (doc.exists) {
        var diffInHours =
          (new Date() - doc.data().tokenTakenAt.toDate()) / (1000 * 60 * 60);

        if (diffInHours >= 24) {
          this._loginTakeToken();
        } else {
          this.tokenData = doc.data();
          this.tokenData.ref = doc.ref;
          this.tokenData.id = doc.id;
        }
        if (this.userData.role == "superadmin") {
          console.log("TokEN comming!!!!! for updating", doc.ref.path);
          console.log(doc.data().schoolName);
          console.log("diffInHours", diffInHours);
          console.log(this.userData.school._esisUserName);
          console.log(this.userData.school._esisPword);
        }
      } else {
        this._loginTakeToken();
      }
    });

    if (this.userData.school.isKinderGarten) {
      this.headerNames.push(
        ...[
          {
            text: "Утас1",
            align: "start",
            sortable: true,
            value: "phone",
          },
          {
            text: "Утас2",
            align: "start",
            sortable: true,
            value: "phone",
          },
          {
            text: "Элссэн огноо",
            align: "start",
            sortable: true,
            value: "ACTION_DATE",
            width: "10%",
          },
          {
            text: "Идэвx",
            align: "start",
            sortable: true,
            value: "lastLoggedAt",
          },
        ]
      );
    } else {
      this.headerNames.push(
        ...[
          // {
          //   text: "Имэйл",
          //   align: "start",
          //   sortable: true,
          //   value: "email",
          // },
          {
            text: "Имэйл (Medle.mn)",
            align: "start",
            sortable: true,
            value: "EMAIL",
          },
          {
            text: "Утас",
            align: "start",
            sortable: true,
            value: "phone",
          },
          {
            text: "Элссэн огноо",
            align: "start",
            sortable: true,
            value: "ACTION_DATE",
            width: "10%",
          },
          {
            text: "Идэвx",
            align: "start",
            sortable: true,
            value: "lastLoggedAt",
          },
          {
            text: "Эцсийн дүн",
            value: "actions",
            sortable: false,
            class: "text-secondary font-weight-bolder opacity-7",
          },
        ]
      );
    }
    if (this.userData && this.userData.ppschool != null) {
      this.path =
        this.userData.ppschool +
        "/students-" +
        this.userData.school.currentYear;

      if (fb.EYESH_APP == false) {
        this.query = fb.db
          .collection(this.path)
          .orderBy("firstName", "asc")
          .where("deleted", "==", false)
          .limit(100);
      } else {
        this.query = fb.db
          .collection(this.path)
          .orderBy("firstName", "asc")
          .where("deleted", "==", false);
      }
    } else {
      this.path = "students";
      this.query = fb.db
        .collectionGroup("students")
        .orderBy("firstName", "asc")
        .where("deleted", "==", false)
        .limit(500);
    }

    this.selectedYear = this.userData.school
      ? this.userData.school.currentYear
      : null;
    if (this.userData && this.userData.school) {
      this.findDepartmentsAndClassGroups(this.selectedYear);
    }
    this.query.onSnapshot((querySnapshot) => {
      this.users = [];
      querySnapshot.forEach((doc) => {
        let student = doc.data();
        student.id = doc.id;
        student.ref = doc.ref;
        student.children = null;
        if (student.moved) {
          if (
            this.userData.school &&
            this.userData.school.currentYear &&
            student["departmentName-" + this.userData.school.currentYear]
          ) {
            student.className =
              student["departmentName-" + this.userData.school.currentYear];
            if (student["classGroupName-" + this.userData.school.currentYear])
              student.className = student.readfrom_esis
                ? student[
                    "classGroupName-" + this.userData.school.currentYear
                  ].toUpperCase()
                : student.className +
                  student[
                    "classGroupName-" + this.userData.school.currentYear
                  ].toUpperCase();
          }
          if (student.gender != undefined || student.gender != null) {
            student.genderName = student.gender == 1 ? "Эрэгтэй" : "Эмэгтэй";
          }

          if (student.firstName && student.firstName.length > 0) {
            if (student.firstName[0].toUpperCase() != student.firstName[0]) {
              student.firstName =
                student.firstName.charAt(0).toUpperCase() +
                student.firstName.slice(1);
              student.ref.update({ firstName: student.firstName });
            }
          }
          if (student.lastName && student.lastName.length > 0) {
            if (student.lastName[0].toUpperCase() != student.lastName[0]) {
              student.lastName =
                student.lastName.charAt(0).toUpperCase() +
                student.lastName.slice(1);
              student.ref.update({ lastName: student.lastName });
            }
          }
          this.users.push(student);
        }
      });
    });
    //this._getResults();
  },
  watch: {
    showStudentsWithNoRegister() {
      if (this.showStudentsWithNoRegister) {
        for (const ss of this.filteredStudents) {
          console.log(
            ss.LAST_NAME[0] + "." + ss.FIRST_NAME,
            ss.STUDENT_GROUP_NAME
          );
        }
      }
    },
    filterSelectedDepartment() {
      if (this.filterSelectedDepartment) {
        this.filterSelectedDepartmentClassGroup = null;
        fb.db
          .collection(this.path)
          .where(
            "ACADEMIC_LEVEL",
            "==",
            this.filterSelectedDepartment.index.toString()
          )
          .orderBy("firstName", "asc")
          .onSnapshot((querySnapshot) => {
            this.users = [];
            querySnapshot.forEach((doc) => {
              let student = doc.data();
              student.id = doc.id;
              student.ref = doc.ref;

              // if (
              //   student["departmentName-" + this.userData.school.currentYear]
              // ) {
              //   student.className =
              //     student["departmentName-" + this.userData.school.currentYear];
              //   if (
              //     student["classGroupName-" + this.userData.school.currentYear]
              //   )
              //     student.className = student.readfrom_esis
              //       ? student[
              //           "classGroupName-" + this.userData.school.currentYear
              //         ].toUpperCase()
              //       : student[
              //           "classGroupName-" + this.userData.school.currentYear
              //         ].toUpperCase();
              // }
              if (student.deleted == false && !student.moved) {
                this._callFinalYearScoreOfStudent(student, true);
                this.users.push(student);
              }
            });
          });
      }
    },
    filterSelectedDepartmentClassGroup: function (val) {
      if (val) {
        this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .where("STUDENT_GROUP_ID", "==", val.STUDENT_GROUP_ID)
          .orderBy("firstName", "asc")
          .get()
          .then((docs) => {
            this.users = [];
            docs.docs.forEach((doc) => {
              var stud = doc.data();
              stud.ref = doc.ref;
              stud.id = doc.id;
              if (!stud.moved) this.users.push(stud);
            });
          });
      }
      // if (this.users) {
      //   this.users.forEach((item) => {
      //     if (
      //       this.filterSelectedDepartmentClassGroup &&
      //       item["classGroup-" + this.userData.school.currentYear] &&
      //       item["classGroup-" + this.userData.school.currentYear].path ==
      //         this.filterSelectedDepartmentClassGroup.ref.path
      //     ) {
      //       list.push(item);
      //     }
      //   });
      // }
    },
    // selectedYear: function (val) {
    //   console.log(val);
    //   this.selectedDepartment = null;
    //   this.selectedClassGroup = null;
    //   this.findDepartmentsAndClassGroups(val);
    // },
  },

  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
    formatDate2(val) {
      if (!val) {
        return "-";
      }
      let date = new Date(val);
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
