<template>
  <v-container class="mx-0 px-2" fluid>
    <v-tabs v-model="selectedTab" class="mx-4">
      <v-tab :class="{ 'active-tab': selectedTab === 0 }">
        {{ $attrs.lesson.classGroups[0].classGroupName }} - Үнэлгээ нэгтгэл
      </v-tab>
      <v-tab :class="{ 'active-tab': selectedTab === 1 }">
        Үнэлгээний үзүүлэлт
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab" style="background-color: white">
      <v-tab-item style="background-color: white" class="mt-0">
        <Unelgee1Overview
          v-if="filterStudentsByClassGroup && examInfos"
          :students="$attrs.students"
          :lesson="$attrs.lesson"
          :examInfos="examInfos"
          :allScores="allScores"
          v-bind="$attrs"
          :semesterNumber="$attrs.semesterNumber"
          :teacher="selectedTeacher"
        >
        </Unelgee1Overview>
      </v-tab-item>
      <v-tab-item style="background-color: white" class="mt-4">
        <Unelgee1Exams
          v-if="examInfos"
          :students="$attrs.students"
          :lesson="$attrs.lesson"
          :semesterNumber="$attrs.semesterNumber"
          :examInfos="examInfos"
          :allScores="allScores"
          v-bind="$attrs"
          :teacher="selectedTeacher"
        >
        </Unelgee1Exams>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import Unelgee1Overview from "./Unelgee1Overview.vue";
import Unelgee1Exams from "./Unelgee1Exams.vue";
import { sync } from "vuex-pathify";
// const fb = require("@/firebaseConfig.js");
export default {
  data() {
    return {
      selectedTab: 0,
      filterStudentsByClassGroup: null,
      tabClassGroups: null,
      examInfos: null,
      allScores: null,
    };
  },
  computed: {
    ...sync("*"),
    selectedTeacher() {
      return this.teacher;
    },
  },
  components: {
    Unelgee1Overview,
    Unelgee1Exams,
  },
  props: {
    teacher: {
      type: Object,
    },
  },
  methods: {
    getUserRef() {
      return this.selectedTeacher.ref;
    },
  },
  created() {
    if (
      this.$attrs.lesson.classGroups &&
      this.$attrs.lesson.classGroups.length > 0
    ) {
      this.filterStudentsByClassGroup =
        this.$attrs.lesson.classGroups[0].classGroupRef;
    } else this.filterStudentsByClassGroup = -1;

    console.log(this.$attrs.semesterNumber);

    this.selectedTeacher.school.ref
      .collection("unelgee1-" + this.selectedTeacher.school.currentYear)
      .doc(this.$attrs.lesson.courseInfo.COURSE_ID.toString())
      .collection("exams")
      .where("createdByRef", "==", this.getUserRef())
      .where("semester", "==", this.$attrs.semesterNumber)
      .orderBy("examDate", "asc")
      .onSnapshot((querySnapshot) => {
        this.examInfos = [];
        var counter = 0;
        querySnapshot.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;

          //251232

          if (
            item.esisLessonTypeId &&
            item.esisLessonTypeId ==
              this.$attrs.lesson.esisLessonType.esisLessonTypeId
          ) {
            console.log(item.ref.path);
            if (
              !item["examDate-" + this.$attrs.lesson.id] &&
              item[
                "examDate-" + this.$attrs.lesson.classGroups[0].STUDENT_GROUP_ID
              ]
            ) {
              item["examDate-" + this.$attrs.lesson.id] =
                item[
                  "examDate-" +
                    this.$attrs.lesson.classGroups[0].STUDENT_GROUP_ID
                ];
              item.ref.update(item);
            } else if (
              !item["examDate-" + this.$attrs.lesson.id] &&
              item.examDate
            ) {
              item["examDate-" + this.$attrs.lesson.id] = item.examDate;
              item.ref.update(item);
            }
            counter++;
            item.index = counter;

            // if(item.newVersion24){
            //   if(item.lessonId==this.$attrs.lesson.id)
            //   this.examInfos.push(item);
            // }
            // else
            this.examInfos.push(item);
          } else if (!item.esisLessonTypeId) {
            //25132
            item.lessonRef.get().then((doc) => {
              item.ref.update({
                esisLessonTypeId: doc.data().esisLessonType.esisLessonTypeId,
              });
            });
          }
        });

        this.selectedTeacher.school.ref
          // .collection("courses-" + this.selectedTeacher.school.currentYear)
          // .doc(this.$attrs.lesson.courseInfo.COURSE_ID.toString())
          // .collection("unelgee1-" + this.selectedTeacher.school.currentYear + "-scores")

          .collection("unelgee1-" + this.selectedTeacher.school.currentYear)
          .doc(this.$attrs.lesson.courseInfo.COURSE_ID.toString())
          .collection(
            "/unelgee1-" + this.selectedTeacher.school.currentYear + "-scores"
          )
          .where("semester", "==", this.$attrs.semesterNumber)
          .where("lessonId", "==", this.$attrs.lesson.id)
          // .where("STUDENT_GROUP_ID","in", [100003497414761])
          .onSnapshot((querySnapshot) => {
            console.log("querySnapshot", querySnapshot.docs.length);
            this.allScores = [];
            querySnapshot.forEach((doc) => {
              let item = doc.data();
              item.id = doc.id;
              item.ref = doc.ref;
              //  console.log(item, item.ref.path);
              this.allScores.push(item);
            });

            for (const exam of this.examInfos) {
              // console.log(
              //   exam["scoreSummerizationInfo-" + this.$attrs.lesson.id],
              //   "examId",
              //   exam.id
              // );
              var scoreSummerizationInfo = {
                numberOfLevel1: 0,
                numberOfLevel2: 0,
                numberOfLevel3: 0,
                numberOfLevel4: 0,
                numberOfLevel5: 0,
                numberOfLevel6: 0,
                numberOfLevel7: 0,
                numberOfLevel8: 0,
              };
              // console.log(this.allScores);
              if (this.allScores) {
                for (var scoreUnelgee of this.allScores) {
                  // console.log(scoreUnelgee, "scoreUnelgee");
                  if (
                    scoreUnelgee.scoreLevelNumber == 1 &&
                    scoreUnelgee.examId == exam.id
                  ) {
                    scoreSummerizationInfo.numberOfLevel1++;
                  } else if (
                    scoreUnelgee.scoreLevelNumber == 2 &&
                    scoreUnelgee.examId == exam.id
                  ) {
                    scoreSummerizationInfo.numberOfLevel2++;
                  } else if (
                    scoreUnelgee.scoreLevelNumber == 3 &&
                    scoreUnelgee.examId == exam.id
                  ) {
                    scoreSummerizationInfo.numberOfLevel3++;
                  } else if (
                    scoreUnelgee.scoreLevelNumber == 4 &&
                    scoreUnelgee.examId == exam.id
                  ) {
                    scoreSummerizationInfo.numberOfLevel4++;
                  } else if (
                    scoreUnelgee.scoreLevelNumber == 5 &&
                    scoreUnelgee.examId == exam.id
                  ) {
                    scoreSummerizationInfo.numberOfLevel5++;
                  } else if (
                    scoreUnelgee.scoreLevelNumber == 6 &&
                    scoreUnelgee.examId == exam.id
                  ) {
                    scoreSummerizationInfo.numberOfLevel6++;
                  } else if (
                    scoreUnelgee.scoreLevelNumber == 7 &&
                    scoreUnelgee.examId == exam.id
                  ) {
                    scoreSummerizationInfo.numberOfLevel7++;
                  } else if (
                    scoreUnelgee.scoreLevelNumber == 8 &&
                    scoreUnelgee.examId == exam.id
                  ) {
                    scoreSummerizationInfo.numberOfLevel8++;
                  }
                }
                var tmp = {};
                tmp["scoreSummerizationInfo-" + this.$attrs.lesson.id] =
                  scoreSummerizationInfo;
                // console.log("tmp", tmp);
                exam.ref.update(tmp);
              }
            }
          });
      });
  },
};
</script>
<style>
.active-tab {
  background-color: #ffc107; /* Your desired background color */
  color: #ffffff; /* Your desired text color */
}
</style>
